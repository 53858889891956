////////////////////////////////////////////////////////////
//     							                                      //
//  Program: montly audit inspetion                        //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { PureComponent } from "react";
import { Bar} from "react-chartjs-2";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export class MonAuditInspection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    userData: global.userData ? global.userData : {},
    inspectionData: this.props.inspectionData ? this.props.inspectionData :[],
    auditData: this.props.auditData ? this.props.auditData :[],     
    };

  }
  render() {
    const { isLoading, inspectionData, auditData } = this.props;
    return (
      <>
     <div className="card">
          <div className="card-header">
            <h6 className="card-title tx-14 mg-b-5 dash_head">
              Monthly Audits & Inspection Details{" "}
                {/* <a href="#" className="btn_view">
                  View More
                </a> */}
            </h6>
          </div>
          <div className="card-body">
            <div className="dashboard-five-stacked-chart">
              <div className="chartjs-size-monitor">
                <div className="chartjs-size-monitor-expand">
                  <div className=""></div>
                </div>
              <div className="chartjs-size-monitor-shrink">
                <div className=""></div>
              </div>
            </div>
            {isLoading ? (
              <Skeleton height={200} />
            ) : (
              <Bar
                data={inspectionData ? inspectionData : {}}
                options={auditData ? auditData : {}}
              />
            )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default MonAuditInspection;
