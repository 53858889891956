/*
 * Program: DailyLog.jsx
 * Application: DailyLog
 * Option: to List out of Daily Log Information
 * Developer: Chandra Shekhar
 * Date: 07-04-2022
 */
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import DailyLogService from "../../services/DailyLogService";
import { Row, Col } from "react-bootstrap";
import Moment from "moment";
import back from "./../../assets/images/back.svg";
import DatePicker from "react-datepicker";
import Select from "react-select";
import UserService from "../../services/UserService";
import { Link } from "react-router-dom";
import PropertyService from "../../services/PropertyService";

export class DailyLog extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      addCostPayableAtModal: false,
      created_date: "",
      note: "",
      user_name: "",
      dailyLogTitle: "",
      currentPage: 1,
      records: [],
      user_slug: "",
      userList: [],
      selectedUserList: [],
      created_at: "",
      filter_created_at: "",
      filter_created_on: "",
      dateUserString: "",
      isFilterTrue: false,
      config: {
        sort: {
          column: "daily_log_id",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by log",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "title",
          text: "Title",
          className: "log hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.title ? record.title : "";
          },
        },
        {
          key: "created_at",
          text: "Date Time",
          className: "created_at hand-cursor",
          sortable: true,
          cell: (record) => {
            return Moment.utc(record.created_at).local().format("DD MMM, YY - hh:mm A");
          },
        },
        {
          key: "property_id",
          text: "Property",
          className: "property_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.property_name  ? record.property_name : "";
          },
        },
        {
          key: "created_by",
          text: "Technician",
          className: "created_by hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.full_name ? record.full_name : "";
          },
        },
        {
          key: "note",
          text: "Note",
          className: "note hand-cursor",
          sortable: false,
          cell: (record) => {
            const note = record.note ? this.limitTextTo50Words(record.note) : "";
            return note;
          },
        },
      ],
      isFirstLoad: true,
      lastQueryString: "",
      singleScreen: false,
      fistLoad: false,
    };
    this.openLogDetailModal = this.openLogDetailModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetData = this.resetData.bind(this);
    this.customFilter = this.customFilter.bind(this);
  }

  componentDidMount() {
    const savedFilterState = JSON.parse(localStorage.getItem('dailyLogFilterState'));

    if (savedFilterState) {
      this.setState({
        property_slug: savedFilterState.property_slug,
        user_slug: savedFilterState.user_slug,
        filter_created_at: savedFilterState.filter_created_at ? new Date(savedFilterState.filter_created_at) : null,
        filter_created_on: savedFilterState.filter_created_on ? new Date(savedFilterState.filter_created_on) : null,
        selectedProperty: savedFilterState.selectedProperty,
        selectedUserList: savedFilterState.selectedUserList,
        isFilterTrue: true,
      }, () => {
        let start_date = "";
        let end_date = "";
        if (this.state.filter_created_at) {
          start_date = Moment(this.state.filter_created_at).format("YYYY-MM-DD");
        }
        if (this.state.filter_created_on) {
          end_date = Moment(this.state.filter_created_on).format("YYYY-MM-DD");
        }
        let queryString =
          "property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
          "&user_slug=" + (this.state.user_slug ? this.state.user_slug : '') +
          "&is_filter=1" +
          "&created_at=" + (start_date ? start_date : '') +
          "&created_on=" + (end_date ? end_date : '');
        this.getData(queryString);
        this.setState({ tableQueryString: "", customQueryString: queryString });
      });
    } else {
      this.getData();
    }
  }

  limitTextTo50Words(htmlContent) {
    // Parse HTML content to extract text
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const textContent = doc.body.textContent || "";

    // Limit the text content to 50 characters
    const limit = 30;
    let result = textContent.slice(0, limit);

    // Append "..." if the original content is longer than 50 characters
    if (textContent.length > limit) {
        result += '...';
    }

    return result;
  }
  async getData(queryString = "") {
    if (this.state.isFirstLoad) {
      this.setState({ loading: true, isFirstLoad: false });
    }
    // let localSelectedProperty = "";
    // if (localStorage.getItem("activePropertyCount") == 1) {
    //   localSelectedProperty = {
    //     label: localStorage.getItem("singlePropertyName"),
    //     value: localStorage.getItem("singlePorpertySlug"),
    //   };
    // } else {
    //   if (localStorage.getItem("outSidePorpertySlug") !== undefined &&
    //     localStorage.getItem("outSidePorpertySlug") !== null &&
    //     localStorage.getItem("outSidePorpertySlug") !== "") {
    //     let propertySlug = localStorage.getItem("outSidePorpertySlug");
    //     let propertyName = localStorage.getItem("selectedPropertyName");
    //     queryString = "property_slug=" + propertySlug;
    //    localSelectedProperty = {
    //       label: propertyName,
    //       value: propertySlug,
    //     };
    //   }
    // }
    let propertySlug = "";
    let propertyName = "";
    let localSelectedProperty = "";
    if (localStorage.getItem("activePropertyCount") == 1) {
      localSelectedProperty = {
        label: localStorage.getItem("singlePropertyName"),
        value: localStorage.getItem("singlePorpertySlug"),
        };
    } else {
      if (this.state.singleScreen === true) {
        localSelectedProperty = this.state.selectedProperty;
          if (queryString == "resetCall") {
            localSelectedProperty = [];
          }
      } else {
        if (localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
          localStorage.getItem("outSidePorpertySlug") !== "") {
        propertySlug = localStorage.getItem("outSidePorpertySlug");
        propertyName = localStorage.getItem("selectedPropertyName");
        if (this.state.fistLoad === false) {
          queryString = "property_slug=" + propertySlug;
          this.setState({fistLoad:true});
        } else {
          queryString = queryString + "&property_slug=" + propertySlug;
}
         localSelectedProperty = {
          label: propertyName,
          value: propertySlug,
        };
        } else {
          localSelectedProperty = this.state.selectedProperty;
          if (queryString == "resetCall") {
            localSelectedProperty = [];
          }
          
      }
      }
     
    }
    var list = [];
    var totalRecords = 0;
    this.setState({ records: [] });
    let res = await DailyLogService.getList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data.total;
    }
    if (queryString !== "resetCall") {
      this.setState({ showFilter: false });
    }
    this.setState({
      loading: false,
      isFilterTrue: false,
      total_record: totalRecords,
      records: list,
      selectedProperty:localSelectedProperty,
    });
  }

  // Get Users List
  async getUserList(queryString = "") {
    var userList = [];
    var userListData = [];
    let res = await UserService.getUserList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      userList = res.data ? res.data : [];
    }

    userList.forEach((item) => {
      if (item.role_id >= global.roleTypeId) {
        userListData.push({
          label: item.full_name + "/" + item.email,
          value: item.slug,
        });
      }
    });
    this.setState({ userList: userListData });
  }

  customFilter() {
    let start_date = "";
    let end_date = "";
    if (this.state.filter_created_at) {
      start_date = Moment(this.state.filter_created_at).format("YYYY-MM-DD");
    }
    if (this.state.filter_created_on) {
      end_date = Moment(this.state.filter_created_on).format("YYYY-MM-DD");
    }
    let queryString =
    "property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
    "&user_slug=" + (this.state.user_slug ? this.state.user_slug : '') +
    "&is_filter=1" +
    "&created_at=" + (start_date ? start_date : '') +
    "&created_on=" + (end_date ? end_date : '');
    this.setState({ lastQueryString: queryString });
    this.setState({ isFilterTrue: true });
    // Save the filter state to local storage
  localStorage.setItem('dailyLogFilterState', JSON.stringify({
    property_slug: this.state.property_slug,
    user_slug: this.state.user_slug,
    filter_created_at: this.state.filter_created_at,
    filter_created_on: this.state.filter_created_on,
    selectedProperty: this.state.selectedProperty,
    selectedUserList: this.state.selectedUserList,
  }));
    this.getData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }

  resetData() {
    this.setState({
      filter_created_at: "",
      filter_created_on: "",
      selectedUserList: [],
      user_slug: "",
      date: "",
      lastQueryString: "",
      singleScreenProperty: "",
      singleScreen: false,
    });
    // Clear the filter state from local storage
   localStorage.removeItem('dailyLogFilterState');
    this.getData("resetCall");
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
      document.body.classList.remove("bdy_over");
    } else {
      this.setState({ showFilter: 1 });
      this.getUserList();
      this.getProperty();
      document.body.classList.add("bdy_over");
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  // Technician/User Change Handler
  handleChangeUserList(value) {
    let user_slug = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        user_slug.push(item.value);
      });
    }
    this.setState({
      selectedUserList: value,
      user_slug: value.value,
    });
  }

  tableChangeHandler = (data) => {
  
    // Function to create query parameters
    const createQueryParams = (data) => {
      const params = new URLSearchParams();
      Object.keys(data).forEach((key) => {
        if (key === "sort_order" && data[key]) {
          params.append("sort_order", data[key].order);
          params.append("sort_column", data[key].column);
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
              data[key] = "asc";
            } else if (key === "page_size") {
              newKey = "per_page";
            }
          params.append(newKey, data[key]);
        }
      });
      
      return params.toString();
    };
    
    const pageSize = Number(data.page_size);
    const validPageSizes = [10,20, 50, 100];

    let possible = this.state.total_record / pageSize + (this.state.total_record % pageSize ? 1 : 0);

    if (data.page_number > possible || ( pageSize && !validPageSizes.includes(pageSize))) {
      data.page_number = 1; 
    }
    let queryString = createQueryParams(data);
  
    if (data.filter_value === "") {
      queryString += `&${this.state.customQueryString}`;
    }
  
    this.setState({ tableQueryString: queryString });
  
    if (data.filter_value !== this.state.filter_value) {  
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      if (this.state.firstLoad === true) {
        queryString = new URLSearchParams({
          page: data.page_number,
          page_size: data.page_size,
          sort_order: data.sort_order.order,
          sort_column: data.sort_order.column,
        }).toString();
      }
  
      this.getData(`${queryString}${this.state.lastQueryString}`);
    }
  };
  //get property data
  async getProperty(queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    if (propertyList.length > 0) {
      propertyList.forEach((item) => {
        propertyListData.push({
          label: item.property_name,
          value: item.slug,
        });
      });
    }
    this.setState({
      propertyList: propertyListData,
    });
  }
   //property type handle
   handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.setState({
      selectedProperty: value,
      property_slug: value.value,
      singleScreen: (localStorage.getItem("outSidePorpertySlug") !== undefined &&
      localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== "" ? true:false),
      singleScreenProperty: value.label,
    });
  }
  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("/daily-log/" + data.slug);
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  openLogDetailModal(slug, rowIndex) {
    this.getDailyLogDetails(slug, rowIndex);
    this.setState({ addCostPayableAtModal: true });
    this.setState({ slug: slug });
  }

  closeLogDetailAtModal() {
    this.setState({ title: "", dateUserString: "", user_name: "", note: "" });
    this.setState({ addCostPayableAtModal: false });
  }

  async getDailyLogDetails(slug) {
    if (slug) {
      var resData = {};
      let res = await DailyLogService.getDetails(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        let dateUserString =
          Moment(resData.created_at).format("DD MMM, YY - hh:mm A") +
          " . " +
          resData.user.full_name;
        this.setState({
          note: resData.note,
          title: resData.title,
          dateUserString: dateUserString,
        });
      }
      this.setState(resData);
    }
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>Daily Logs</h3>
                        <div className="btnNavigate d-flex justify-content-end">
                          {global.userPermissions.checkPermission("daily_logs-add") && (
                        <Link to={"/daily-log/add"}>
                          <button className="gf-primary-btn-small-one mg-l-7">
                            Add Daily Log
                          </button>
                        </Link>)}
                          <button
                            onClick={this.filterToggle}
                            className="gf-primary-btn-small-one mg-l-7"
                          >
                            {" "}
                            {this.state.showFilter === 1 ? "Filter" : "Filter"}
                          </button>
                        </div>
                      </div>
                      <span>All Daily Logs</span>
                    </h3>
                  </div>

                  {this.state.showFilter ? (
                    <Row className="align-items-start fliter_back">
                      <div className="order_form">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <h3
                            className="hand-cursor back-size"
                            onClick={this.filterToggle}
                          >
                            <img src={back} alt="" />
                            Back
                          </h3>
                        </div>
                        <h3>Filter</h3>
                        <Row className="align-items-start justify-content-between m-0 p-0">
                        <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <label className="form-label text-left mg-b-5 mt-0">
                              Property{" "}
                            </label>
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedProperty}
                              options={this.state.propertyList}
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeProperty(value)
                              }
                              defaultValue={this.state.selectedStatusList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0 pb-0"
                          >
                            <label className="form-label text-left mg-b-5">
                              Start Date{" "}
                            </label>
                            <DatePicker
                              className="form-control max_width_100"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="filter_created_at"
                              placeholderText="dd-mm-yy"
                              dateFormat="dd MMM yy"
                              selected={this.state.filter_created_at}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "filter_created_at",
                                    value: data,
                                  },
                                })
                              }
                              maxDate={this.state.filter_created_on}
                            />
                          </Col>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0 pb-0"
                          >
                            <label className="form-label text-left mg-b-5">
                              End Date{" "}
                            </label>
                            <DatePicker
                              className="form-control max_width_100"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="filter_created_on"
                              placeholderText="dd-mm-yy"
                              dateFormat="dd MMM yy"
                              selected={this.state.filter_created_on}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "filter_created_on",
                                    value: data,
                                  },
                                })
                              }
                              minDate={this.state.filter_created_at}
                            />
                          </Col>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <label className="form-label text-left mg-b-5">
                              technician{" "}
                            </label>
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedUserList}
                              options={this.state.userList}
                              onChange={(value) =>
                                this.handleChangeUserList(value)
                              }
                              defaultValue={this.state.selectedUserList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                          <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                            <button
                              onClick={this.resetData}
                              className="btn-success-outline"
                            >
                              Reset
                            </button>
                            <button
                              onClick={this.customFilter}
                              className="btn-success mg-l-0 mg-r-20"
                            >
                              {this.state.isFilterTrue
                                ? global.loader
                                : "Search"}
                            </button>
                          </Row>
                        </Row>
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="brandList dashboardRightcard">
            {localStorage.getItem("outSidePorpertySlug") ? (<>
                <div className="row">
                <div className="col-md-12">
                    <div className="checklist_table_heading "><span className="lspan">Search Applied On : </span><span className="rspan">{this.state.singleScreenProperty ? this.state.singleScreenProperty:localStorage.getItem("selectedPropertyName") }</span></div>
                </div>
              </div>
              </>) :""}
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default DailyLog;
