/*
 * Program: UserService.js
 * Application: User Service
 * Option: Used for User Service Information
 * Developer: Chandra Shekhar
 * Date: 2022-03-31
 */
import ApiService from "./ApiService";

class UserService {
  getUserList(queryString) {
    let endPoint = "user-list" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getUser(slug) {
    let endPoint = "user-show/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createUser(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "user-add";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateUser(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "user-update/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateUserBlocked(data) {
     // Delete data which we don't want to send
     delete data.errors;
     delete data.input;
 
     let endPoint = "unblock-user";
     let addditionalHeaderData = {};
     let postData = data;
 
     let inputData = {
       endPoint: endPoint,
       addditionalHeaderData: addditionalHeaderData,
       postData: postData,
     };
     let response = ApiService.postCall(inputData);
     return response;
  }

  deleteUser(slug) {
    let endPoint = "user-delete/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  resentInvitation(slug) {
    let endPoint = "user-invite/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getRoleList(queryString) {
    let endPoint = "user-role" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getPermissionModule(slug) {
    let endPoint = "user-permission-module/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  setRolePermissionStatusList(slug, data) {
    let endPoint = "set-role-based-permission-status/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new UserService();
