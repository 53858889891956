/*
 * Program: User.jsx
 * Application: User
 * Option: to List out of User Information
 * Developer: Chandra Shekhar
 * Date: 2022-03-31
 */
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import UserService from "../../services/UserService";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import back from "./../../assets/images/back.svg";
import Select from "react-select";
import PropertyService from "../../services/PropertyService";
import CustomerService from "../../services/CustomerService";
export class User extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      records: [],
      selectRoleList: [],
      hideCustomerList: 0,
      // hidePropertyList: 0,
      customer_slug: "",
      customerList: [],
      selectedCustomer: [],
      selectedCustomerList: [],
      property_slug: [],
      propertyList: [],
      selectedProperties: [],
      selectedPropertyList: [],
      selectedRoleType: "",
      selectedRoleList: [],
      isFilterTrue: false,
      config: {
        sort: {
          column: "first_name",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        // show_filter: false,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by first name, last name, email id, job title",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "first_name",
          text: "First Name",
          className: "first_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.first_name ? record.first_name : "";
          },
        },
        {
          key: "last_name",
          text: "Last Name",
          className: "last_name hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.last_name ? record.last_name : "";
          },
        },
        {
          key: "email",
          text: "Email ID",
          className: "email hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        {
          key: "phone",
          text: "Contact Number",
          className: "phone hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.phone
              ? record.phone.substring(0, 3) +
                  "-" +
                  record.phone.substring(3, 6) +
                  "-" +
                  record.phone.substring(6, record.phone.length)
              : "";
          },
        },
        {
          key: "job_title",
          text: "Job Title",
          className: "role_id hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.job_title ? record.job_title : "";
          },
        },
        {
          key: "role_id",
          text: "Role",
          className: "role_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.role_type_name;
          },
        },
        {
          key: "property_name",
          text: "Property",
          className: "email hand-cursor",
          sortable: false,
          cell: (record) => {
            // Split the properties list into an array
            let propertyListArray = record.propertiesLists.split(",");

            // Get the total count of properties
            let propertyCount = propertyListArray.length;

            // Variable to store the property list
            var propertyList;

            // Check if there are more than 2 properties
            if (propertyCount > 1) {
              // Concatenate the first two properties with a "More" link
              propertyList = propertyListArray.slice(0, 1).concat(" ",
                 <strong>+<Link to={`${record.slug}`}>{propertyCount - 1} More</Link></strong>
              );
            } else {
              // If there are 2 or fewer properties, only include them in the list
              propertyList = propertyListArray;
            }

            if(record.role_type_name === "System Admin")
            {
              return "All Properties";
            }

            // Return the constructed property list
            return propertyList;

          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className={record.status_id === 1 && record.is_blocked === 1 ? "status-inactive" : "status-active"}>
                {record.status_id_disp ? record.status_id_disp : ""} {record.is_blocked === 1 ? '(Blocked)' : ""}
              </b>
            ) : (
              <b className="status-inactive">
                {record.status_id_disp ? record.status_id_disp : ""} {record.is_blocked === 1 ? '(Blocked)' : ""}
              </b>
            );
          },
        },
      ],
      isFirstLoad: true,
      lastQueryString: "",
      singleScreen: false,
      fistLoad: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetData = this.resetData.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.getPropertyList = this.getPropertyList.bind(this);
  }

  componentDidMount() {
    const savedFilterState = JSON.parse(localStorage.getItem('userFilterState'));
    if (savedFilterState) {
      this.setState({
        role_id: savedFilterState.role_id,
        customer_slug: savedFilterState.customer_slug,
        property_slug: savedFilterState.property_slug,
        status: savedFilterState.status,
        selectedRoleList: savedFilterState.selectedRoleList,
        selectedCustomerList: savedFilterState.selectedCustomerList,
        selectedPropertyList: savedFilterState.selectedPropertyList,
        selectedStatus: savedFilterState.selectedStatus,
        isFilterTrue: true,  // Assuming that if there's a saved filter state, the filter is active
      }, () => {
        let queryString =
          "role_id=" + (this.state.role_id ? this.state.role_id : '') +
          "&is_filter=1&" +
          "customer_slug=" + (this.state.customer_slug ? this.state.customer_slug : '') +
          "&property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
          "&status=" + (this.state.status ? this.state.status : '');
          
        this.getData(queryString);
        this.getPropertyList();
        this.setState({ tableQueryString: "", customQueryString: queryString });
      });
    } else {
      this.getData();
      this.getPropertyList();
    }
  }

  async getData(queryString = "") {
    if (this.state.isFirstLoad) {
      this.setState({ loading: true, isFirstLoad: false });
    }
    let propertySlug = "";
    let propertyName = "";
    let localSelectedProperty = "";

    if (localStorage.getItem("activePropertyCount") == 1) {
      localSelectedProperty = {
        label: localStorage.getItem("singlePropertyName"),
        value: localStorage.getItem("singlePorpertySlug"),
        };
    } else {
      if (this.state.singleScreen === true) {
        localSelectedProperty = this.state.selectedPropertyList;
          if (queryString == "resetCall") {
            localSelectedProperty = [];
          }
      } else {
        if (localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
          localStorage.getItem("outSidePorpertySlug") !== "") {
        propertySlug = localStorage.getItem("outSidePorpertySlug");
        propertyName = localStorage.getItem("selectedPropertyName");
        if (this.state.fistLoad === false) {
          queryString = "property_slug=" + propertySlug;
          this.setState({fistLoad:true});
        } else {
          queryString = queryString + "&property_slug=" + propertySlug;
}
         localSelectedProperty = {
          label: propertyName,
          value: propertySlug,
        };
        } else {
          localSelectedProperty = this.state.selectedPropertyList;
          if (queryString == "resetCall") {
            localSelectedProperty = [];
          }
          
      }
      }
     
    }
    let list = [];
    var totalRecords = 0;
    let res = await UserService.getUserList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data.total;
    }
    if (queryString !== "resetCall") {
      this.setState({ showFilter: false });
    }
    this.setState({
      loading: false,
      isFilterTrue: false,
      total_record: totalRecords,
      records: list,
      selectedPropertyList:localSelectedProperty,
    });
  }

  getRoleList() {
    const roleList = global.selectRoleList;
    let roles = [];
    if (roleList != null && roleList.length > 0) {
      roleList.forEach((item, i) => {
       // if (item.value > global.roleTypeId) {
          roles.push({
            value: item.value,
            label: item.name,
          });
       // }
      }); 
    }
    this.setState({ selectRoleList: roles });
  }

  // Get Customer List
  async getCustomerList(queryString = "") {
    var customerList = [];
    var customerListData = [];
    let res = await CustomerService.getCustomerList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      customerList = res.data ? res.data : [];
    }
    this.setState({ customerList: customerList });
    customerList.forEach((item) => {
      customerListData.push({
        label: item.customer_name,
        value: item.slug,
      });
    });
    this.setState({ customerList: customerListData });
  }


  async getPropertyList(customerSlug = "", queryString = "",) {
    this.setState({ loading: true });
    var propertyList = [];
    var propertyListData = [];
    var res = ""
    if (customerSlug != "") {
      this.setState({ selectedPropertyList: [] });
      res = await PropertyService.getPropertyByCustomer(
        "",
        (queryString = "customer_slug=" + customerSlug)
      );

    } else {
      res = await PropertyService.getPropertyList(
        "is_dropdown=1" + (queryString ? "&" + queryString : "")
      );
    }

    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    propertyList.forEach((item) => {
      propertyListData.push({
        label: item.property_name,
        value: item.slug,
      });
    });


    this.setState({ propertyList: propertyListData });
    this.setState({ loading: false });
  }

  async getPropertyData(customerSlug = "", queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    var propertyListCount = 0;
    let res = await PropertyService.getPropertyByCustomer(
      "",
      (queryString = "customer_slug=" + customerSlug)
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListCount = propertyList.length;
    }
    this.setState({ propertyListTotal: propertyListCount });
    if (this.state.propertyListTotal > 0) {
      propertyList.forEach((item) => {
        propertyListData.push({
          value: item.slug,
          label: item.property_name,
        });
      });
    }
    this.setState({ propertyList: propertyListData });
  }

  customFilter() {
    let queryString =
    "role_id=" + (this.state.role_id ? this.state.role_id : '') +
    "&is_filter=1&" +
    "customer_slug=" + (this.state.customer_slug ? this.state.customer_slug : '') +
    "&property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
    "&status=" + (this.state.status ? this.state.status : '');
    this.setState({ lastQueryString: queryString });
    this.setState({ isFilterTrue: true });
    localStorage.setItem('userFilterState', JSON.stringify({
      role_id: this.state.role_id,
      customer_slug: this.state.customer_slug,
      property_slug: this.state.property_slug,
      status: this.state.status,
      selectedRoleList: this.state.selectedRoleList,
      selectedCustomerList: this.state.selectedCustomerList,
      selectedPropertyList: this.state.selectedPropertyList,
      selectedStatus: this.state.selectedStatus,
    }));
    this.getData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }

  resetData() {
    this.setState({
      selectedRoleType: "",
      selectedPropertyList: [],
      selectedCustomerList: [],
      customerList: [],
      role_id: "",
      customer_slug: "",
      property_slug: "",
      selectedRoleList: [],
      lastQueryString: "",
      singleScreenProperty: "",
      singleScreen: false,
      status:"",
      selectedStatus : "",
    });
    localStorage.removeItem('userFilterState');
    this.getData("resetCall");
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
      document.body.classList.remove("bdy_over");
    } else {
      this.setState({ showFilter: 1 });
      this.getRoleList();
      document.body.classList.add("bdy_over");
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeRole(value) {
    if (value != null) {
      /* use to hide show the dropdown list start */
      this.setState({
        selectedPropertyList: [],
        selectedCustomerList: [],
        customerList: [],
        // propertyList: [],
        selectedRoleType: value.value,
      });
      if (value.value === "2") {
        this.setState({ hideCustomerList: 1 });
        // this.setState({ hidePropertyList: 0 });
        this.getCustomerList();
      } else if (value.value === "3" || value.value === "5" ||value.value === "6") {
        this.setState({ hideCustomerList: 1 });
        // this.setState({ hidePropertyList: 1 });
        this.getCustomerList();
      } else if (value.value === "4") {
        this.setState({ hideCustomerList: 0 });
        this.setState({ hidePropertyList: 1 });
        this.getPropertyData("&property_user=1");
      } else {
        this.setState({ hideCustomerList: 0 });
        // this.setState({ hidePropertyList: 0 });
      }
      /* use to hide show the dropdown list end*/
      this.setState({
       selectedRoleList: value,
        role_id: value.value,
      });
    }
  }
  // Customer Change Handler
  handleChangeCustomerList(value) {
    this.setState({
      selectedCustomerList: value,
      selectedPropertyList: [],
      customer_slug: value.value,
    });
    if (this.state.selectedRoleType == 3 || this.state.selectedRoleType == 5 || this.state.selectedRoleType == 6) {
      this.getPropertyData(value.value);
    }
  }

  // Properties Change Handler
  handleChangePropertyList(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.setState({
      selectedPropertyList: value,
      property_slug: value.value,
      singleScreen: (localStorage.getItem("outSidePorpertySlug") !== undefined &&
      localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== "" ? true:false),
      singleScreenProperty: value.label,
    });
  }

  tableChangeHandler = (data) => {
  
    // Function to create query parameters
    const createQueryParams = (data) => {
      const params = new URLSearchParams();
      Object.keys(data).forEach((key) => {
        if (key === "sort_order" && data[key]) {
          params.append("sort_order", data[key].order);
          params.append("sort_column", data[key].column);
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          }
          params.append(newKey, data[key]);
        }
      });
      return params.toString();
    };
    
    const pageSize = Number(data.page_size);
    const validPageSizes = [10,20, 50, 100];

    let possible = this.state.total_record / pageSize + (this.state.total_record % pageSize ? 1 : 0);

    if (data.page_number > possible || ( pageSize && !validPageSizes.includes(pageSize))) {
      data.page_number = 1; 
    }
    let queryString = createQueryParams(data);
  
    if (data.filter_value === "") {
      queryString += `&${this.state.customQueryString}`;
    }
  
    this.setState({ tableQueryString: queryString });
  
    if (data.filter_value !== this.state.filter_value) {  
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      if (this.state.firstLoad === true) {
        queryString = new URLSearchParams({
          page: data.page_number,
          page_size: data.page_size,
          sort_order: data.sort_order.order,
          sort_column: data.sort_order.column,
        }).toString();
      }
      this.getData(`${queryString}&${this.state.lastQueryString}`);
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("user/update/" + data.slug);
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }
  handleChangeStatus(value){
    let status = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status.push(item.value);
      });
    }
    this.setState({
      selectedStatus: value,
      status: value.value,
    });
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div
                    className="dashbaord-pageHeadertitle"
                  >
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>Users</h3>
                        <div className="btnNavigate d-flex justify-content-end">
                        {global.userPermissions.checkPermission("user-add") && (
                          <Link to={"/user/add"}>
                            <button className="gf-primary-btn-small-one mg-l-7 btn btn-primary ">
                              Add Users
                            </button>
                          </Link>
                           )}
                          <button
                            onClick={this.filterToggle}
                            className="gf-primary-btn-small-one mg-l-7 btn btn-primary"
                          >
                            {" "}
                            {this.state.showFilter === 1 ? "Filter" : "Filter"}
                          </button>
                        </div>
                      </div>
                      <span>All Users</span>
                    </h3>
                  </div>

                  {this.state.showFilter ? (
                    <Row className="align-items-start fliter_back">
                      <div className="order_form">
                        <div className="d-flex justify-content-between align-items-center w-100 ">
                          <h3
                            className="hand-cursor back-size"
                            onClick={this.filterToggle}
                          >
                            <img src={back} alt="" />
                            Back
                          </h3>
                        </div>
                        <h3>Filter</h3>
                        <div className="filter-box-scroll">
                        <Row className="align-items-start justify-content-between m-0 p-0">
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0 pb-0"
                          >
                            <label className="form-label text-left mg-b-5 mt-0 ">
                              Role{" "}
                            </label>
                            <Select
                              className="multiselect new_drop select_width"
                              menuPlacement="auto"
                              value={this.state.selectedRoleList}
                              options={this.state.selectRoleList}
                              onChange={(value) => this.handleChangeRole(value)}
                              defaultValue={this.state.selectedRoleList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                          {this.state.hideCustomerList === 1 ? (
                            <>
                              {/* Customer Admn */}
                              <Col
                                md={12}
                                lg={12}
                                xl={12}
                                className="mb-md-0 mb-3 pl-0 pb-0"
                              >
                                <label className="form-label text-left mg-b-5">
                                  Brand Name{" "}
                                </label>
                                <Select
                                  className="multiselect new_drop"
                                  menuPlacement="auto"
                                  value={this.state.selectedCustomerList}
                                  options={this.state.customerList}
                                  onChange={(value) =>
                                    this.handleChangeCustomerList(value)
                                  }
                                  defaultValue={this.state.selectedCustomerList}
                                  autoComplete="off"
                                  getOptionValue={(option) => `${option.label}`}
                                  styles={customStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary75: "#808080",
                                      primary50: "#808080",
                                      primary25: "#808080",
                                    },
                                  })}
                                />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                          {/* Property List */}
                          {/* {this.state.hidePropertyList === 1 ? ( */}
                            <>
                              <Col
                                md={12}
                                lg={12}
                                xl={12}
                                className="mb-md-0 mb-3 pl-0 pb-0"
                              >
                                <label className="form-label text-left mg-b-5">
                                  Property{" "}
                                </label>
                                <Select
                                  className="multiselect new_drop"
                                  menuPlacement="auto"
                                  value={this.state.selectedPropertyList}
                                  options={this.state.propertyList}
                                  onChange={(value) =>
                                    this.handleChangePropertyList(value)
                                  }
                                  defaultValue={this.state.selectedPropertyList}
                                  autoComplete="off"
                                  getOptionValue={(option) => `${option.label}`}
                                  styles={customStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary75: "#808080",
                                      primary50: "#808080",
                                      primary25: "#808080",
                                    },
                                  })}
                                />
                              </Col>
                            </>
                            <>
                              <Col
                                md={12}
                                lg={12}
                                xl={12}
                                className="mb-md-0 mb-3 pl-0 pb-0"
                              >
                                <label className="form-label text-left mg-b-5">
                                  Status
                                </label>
                                <Select
                                  className="multiselect new_drop"
                                  menuPlacement="auto"
                                  value={this.state.selectedStatus}
                                  options={global.activeStatusWeb }
                                  onChange={(value) =>
                                    this.handleChangeStatus(value)
                                  }
                                  defaultValue={this.state.selectedStatus}
                                  autoComplete="off"
                                  getOptionValue={(option) => `${option.label}`}
                                  styles={customStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary75: "#808080",
                                      primary50: "#808080",
                                      primary25: "#808080",
                                    },
                                  })}
                                />
                              </Col>
                            </>

                          <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                            <button
                              onClick={this.resetData}
                              className="btn-success-outline"
                            >
                              Reset
                            </button>
                            <button
                              onClick={this.customFilter}
                              className="btn-success mg-l-0 mg-r-20"
                            >
                              {this.state.isFilterTrue
                                ? global.loader
                                : "Search"}
                            </button>
                          </Row>
                        </Row>
                        </div>

                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="mg-tneg-4 brandList dashboardRightcard">
            {localStorage.getItem("outSidePorpertySlug") ? (<>
                <div className="row">
                <div className="col-md-12">
                    <div className="checklist_table_heading "><span className="lspan">Search Applied On : </span><span className="rspan">{this.state.singleScreenProperty ? this.state.singleScreenProperty:localStorage.getItem("selectedPropertyName") }</span></div>
                </div>
              </div>
              </>) :""}
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default User;
