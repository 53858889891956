////////////////////////////////////////////////////////////
//     							                                      //
//  Program: MRO status card                             //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Doughnut} from "react-chartjs-2";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export class MroStatus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userData: global.userData ? global.userData : {},
      mroPieData: props.mroPieData || {
        datasets: [{ data: [0, 0, 0, 0, 0, 0] }],
      },
      doughnutPieOptions: this.props.doughnutPieOptions
        ? this.props.doughnutPieOptions
        : [],
    };
  }
  render() {
    const { isLoading, mroPieData, doughnutPieOptions } = this.props;
    const hasData = mroPieData?.datasets && mroPieData?.datasets.length > 0;

    const pending = hasData ? mroPieData.datasets[0].data[0] : 0;
    const onGoing = hasData ? mroPieData.datasets[0].data[1] : 0;
    const overdue = hasData ? mroPieData.datasets[0].data[2] : 0;
    const completed = hasData ? mroPieData.datasets[0].data[3] : 0;
    const backlog = hasData ? mroPieData.datasets[0].data[4] : 0;
    const cancelled = hasData ? mroPieData.datasets[0].data[5] : 0;
    return (
      <div className="card h-chart_1">
        <div className="card-header">
          <h6 className="card-title tx-14 mg-b-5 dash_head">
            MROs Status{" "}
            {global.userPermissions.checkPermission("mros-list") && (
              <Link to="/mros" className="btn_view">
                View More
              </Link>
            )}
          </h6>
        </div>
        <div className="card-body row pd-25">
          <div className="col-sm-12 col-md-12 donut_mro_box">
            {/* Display skeleton or actual Doughnut chart based on loading state */}
            {isLoading ? (
              <Skeleton height={150} />
            ) : (
              <Doughnut
                data={mroPieData}
                options={doughnutPieOptions}
                height={150}
              />
            )}
          </div>
          <div className="col-sm-12 col-md-12 mg-t-30 mg-sm-t-0">
            <ul className="list-unstyled">
              {/* Display skeleton loaders for the list or actual list */}
              {isLoading ? (
                Array(6).fill().map((_, index) => (
                  <li key={index} className="d-flex align-items-center mg-t-5">
                    <Skeleton width={20} height={20} className="mg-r-10" />
                    <Skeleton width={100} />
                  </li>
                ))
              ) : (
                <>
                  <li className="d-flex align-items-center mg-t-5">
                    <span className="d-inline-block wd-10 ht-10 bg-gray-500 mg-r-10"></span>{" "}
                    Pending: {pending}
                  </li>
                  <li className="d-flex align-items-center mg-t-5">
                    <span className="d-inline-block wd-10 ht-10 bg-primary mg-r-10"></span>{" "}
                    On-going: {onGoing}
                  </li>
                  <li className="d-flex align-items-center mg-t-5">
                    <span className="d-inline-block wd-10 ht-10 bg-overdue mg-r-10"></span>{" "}
                    Overdue: {overdue}
                  </li>
                  <li className="d-flex align-items-center mg-t-5">
                    <span className="d-inline-block wd-10 ht-10 bg-complete-cust mg-r-10"></span>{" "}
                    Completed: {completed}
                  </li>
                  <li className="d-flex align-items-center mg-t-5">
                    <span className="d-inline-block wd-10 ht-10 bg-backlog mg-r-10"></span>{" "}
                    Backlog: {backlog}
                  </li>
                  <li className="d-flex align-items-center mg-t-5">
                    <span className="d-inline-block wd-10 ht-10 b-cancalled-cust mg-r-10"></span>{" "}
                    Cancelled: {cancelled}
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default MroStatus;
