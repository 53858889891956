////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Recent daily log                            //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { PureComponent } from "react";
import Moment from "moment";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export class RecentDailyLog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    userData: global.userData ? global.userData : {},
    dailyLogList: this.props.dailyLogList ? this.props.dailyLogList :[],
      
    };

  }

  render() {
    const { isLoading, dailyLogList } = this.props;
    return (
      <>
      
      <div className="card card-dashboard-sixteen">
        <div className="card-header">
          <h6 className="card-title tx-14 mg-b-0">
            Recent Daily Logs{" "}
            {global.userPermissions.checkPermission("daily_logs-list") && dailyLogList.length > 0 && (
            <Link to="/daily-logs" className="btn_view">
              View More
            </Link>
            )}
          </h6>
        </div>
        <div className="card-body custom-table-box">
          <div className={`table-responsive ${dailyLogList.length === 0 ? 'no-record-data' : ''}`}>
            <table className="table mg-b-0">
              <tbody>
              {isLoading ? (
                  Array(2).fill().map((_, index) => (
                    <tr key={`skeleton_${index}`}>
                      <td>
                        <h6 className="mg-b-0">
                          <Skeleton width={150} />
                        </h6>
                        <small className="tx-11">
                          <Skeleton width={100} />
                        </small>
                      </td>
                      <td>
                        <h6 className="mg-b-0">
                          <Skeleton width={100} />
                        </h6>
                        <small className="tx-11">
                          <Skeleton width={80} />
                        </small>
                      </td>
                    </tr>
                  ))
                ) : 
                dailyLogList.length > 0 ? (
                  dailyLogList.map((el, index) => (
                    <tr key={"daily_" + index}>
                      <td>
                      <Link 
                          to={`/daily-log/${el.slug}`} className="link-style"
                        >
                        <h6 className="mg-b-0 ">
                          {el.title ? el.title : ""}
                        </h6>
                        <small className="tx-11">
                          {el.property
                            ? el.property.property_name
                            : ""}
                        </small>
                        </Link>
                      </td>
                      <td>
                      <Link 
                          to={`/daily-log/${el.slug}`} className="link-style"
                        >
                        <h6 className="mg-b-0 ">
                          {" "}
                          {el.created_at
                            ? Moment(el.created_at).format(
                              "DD MMM, YY - hh:mm A"
                            )
                            : ""}
                        </h6>
                        <small className="tx-11">
                          {el.user ? el.user.full_name : ""}
                        </small>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (<><div className="no-recrod-found" key="1">No record found</div></>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      </>
    );
  }
}
export default RecentDailyLog;
