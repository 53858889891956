////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Audit number card                             //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AuditIcon from '../../assets/images/audit-icon.svg';

export class AuditsNumber extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    userData: global.userData ? global.userData : {},
    audit: this.props.audit ? this.props.audit :0,
      
    };

  }

  render() {
    const { isLoading, audit } = this.props;
    const isPropertyUser = global.roleTypeId === 4;
    const Tag = isPropertyUser ? 'h6' : 'h2';
    const className = isPropertyUser ? 'property-user-title' : 'admin-user-title';

    return (
      <div className="col-md-12 col-12 mg-t-20">
        <div className="card card-dashboard-ratio">
          <div className="card-header">
            <h6 className="card-title tx-14 mg-b-0 dash_head">
              Audits{" "}
              {global.userPermissions.checkPermission("audits-list") && audit > 0 &&  (
              <Link to="/audits" className="btn_view">
                View More
              </Link>
              )}
            </h6>
          </div>
          <div className="card-body justify-content-between">
            <div className="card-icon">
              {!isLoading ? (
                <img src={AuditIcon} className="audit-icon" />
              ) : (
                <Skeleton width={70} height={70} />
              )}
            </div>
            <Tag className={className}>
              {/* Show skeleton loader while data is loading */}
              {isLoading ? (
                <Skeleton width={50} height={30} />
              ) : (
                <>
                  <span className="total-title-inspection">Total Audits</span>
                  {audit !== 0 ? audit : 0}
                </>
              )}
            </Tag>
          </div>
        </div>
      </div>
    );
  }
}

export default AuditsNumber;
