import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import defaultImage from "./../../assets/images/default.png";
import siteLogo from "./../../assets/images/GFBOT-Horizontal.png";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerToken: global.headerToken ? global.headerToken : "",
      userData: global.userData ? global.userData : {},

      profileImage:
        localStorage.getItem("userData") !== null &&
        localStorage.getItem("userData") !== undefined
          ? JSON.parse(localStorage.getItem("userData")).profile_thumb_url
          : defaultImage,

      showSpinner: true,
      customSpinner: parseInt(localStorage.getItem("customSpinnerSession"))
        ? false
        : true,
      loading: false,
      first_name: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).first_name
        : "",
    };

    this.logoutTimeout = null;
    this.resetTimer = this.resetTimer.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.handleStorageEvent = this.handleStorageEvent.bind(this);
  }

  componentDidMount() {
    const token = localStorage.getItem("headerToken");
    if (!token) {
      this.logoutUser();
      return;
    }

    this.setState({ showSpinner: true, loading: true });
    this.setState({ showSpinner: false, loading: false, customSpinner: false });
    localStorage.setItem("customSpinnerSession", 1);

    // Set up event listeners to detect user activity
    window.addEventListener("mousemove", this.resetTimer);
    window.addEventListener("keypress", this.resetTimer);
    window.addEventListener("click", this.resetTimer);
    window.addEventListener("scroll", this.resetTimer);
    window.addEventListener("touchstart", this.resetTimer);
    window.addEventListener("storage", this.handleStorageEvent);
    this.startLogoutTimer();
  }

  componentWillUnmount() {
    // Clean up event listeners and timeout on unmount
    window.removeEventListener("mousemove", this.resetTimer);
    window.removeEventListener("keypress", this.resetTimer);
    window.removeEventListener("click", this.resetTimer);
    window.removeEventListener("scroll", this.resetTimer);
    window.removeEventListener("touchstart", this.resetTimer);
    window.removeEventListener("storage", this.handleStorageEvent);
    clearTimeout(this.logoutTimeout);
  }

  startLogoutTimer() {
    // Log out user after 2 hours (2 * 60 * 60 * 1000 milliseconds)
    this.logoutTimeout = setTimeout(this.logoutUser, 2 * 60 * 60 * 1000);
  }

  resetTimer() {
    clearTimeout(this.logoutTimeout);
    this.startLogoutTimer();
  }

  logoutUser() {
    // Perform logout logic here
    // alert("You have been logged out due to inactivity or missing token.");
    localStorage.removeItem("headerToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("globleTracker");
    localStorage.removeItem("roomLocationTracker");
    localStorage.removeItem("eqpZoneLocationTracker");
    localStorage.removeItem("eqpLocationTracker");
    localStorage.removeItem("comLocationTracker");
    localStorage.removeItem("mroTrackerLocation");
    localStorage.removeItem("globleTrackerProperty");
    localStorage.removeItem("globleTrackerInsepection");
    localStorage.removeItem("singlePropertyName");
    localStorage.removeItem("singlePorpertySlug");
    // this.props.history.push("/signin");
  }

  handleStorageEvent(event) {
    if (event.key === "logout") {
      this.logoutUser();
    }
  }

  getTitle() {
    return process.env.REACT_APP_PROJECT_TITLE
      ? process.env.REACT_APP_PROJECT_TITLE
      : "";
  }

  render() {
    return (
      <div className="az-header">
        <div>
          {this.state.customSpinner ? (
            <div className="customLoad">
              <div className="div1">
                <div>
                  <img
                    className="mg-b-0 customLoadimg"
                    src={require("../../assets/images/default.png").default}
                    alt=""
                  ></img>
                </div>
                <div id="load">
                  <center>
                    <div id="preload1"></div>
                  </center>
                </div>
              </div>
            </div>
          ) : this.state.showSpinner ? (
            global.spinnerLoader
          ) : (
            ""
          )}
          <div className="az-header-menu-header">
            {window.location.pathname === "/dashboard" ? (
              <span className="az-logo">
                <img className="mg-b-0" src={siteLogo} alt="Site Logo" />
              </span>
            ) : (
              <Link to={"/dashboard"} className="az-logo">
                <img className="mg-b-0" src={siteLogo} alt="Site Logo" />
              </Link>
            )}
            <a
              href="#/"
              onClick={(event) => this.toggleHeaderMenu(event)}
              className="close"
            >
              &times;
            </a>
          </div>
          <div className="az-header-menu">
            <ul className="nav">
              <h2 className="menu_heading">Dashboard</h2>
              <li
                className={
                  this.isPathActive("/dashboard")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  to="/dashboard"
                  className="nav-link nav-link-custom d-flex"
                >
                  <span>Dashboard</span>
                </Link>
              </li>
              {global.userPermissions.checkPermission("checklist-list") && (
                <h2 className="menu_heading">Inspections</h2>
              )}
              {global.userPermissions.checkPermission("checklist-list") && (
                <li
                  className={
                    this.isPathActive("/checklists")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/checklists"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Checklists</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("inspection-list") && (
                <li
                  className={
                    this.isPathActive("/inspections") ||
                    this.isPathActive("/inspection/update") ||
                    this.isPathActive("/inspection-perform")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/inspections"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Inspections</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("mros-list") && (
                <li
                  className={
                    this.isPathActive("/mros") ? "nav-item active" : "nav-item"
                  }
                >
                  <Link to="/mros" className="nav-link nav-link-custom d-flex">
                    <span>MROs</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("daily_logs-list") && (
                <li
                  className={
                    this.isPathActive("/daily-logs") ||
                    this.isPathActive("/daily-log/add") ||
                    this.isPathActive("/daily-log/")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/daily-logs"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Daily Logs</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("tracker-list") && (
                <li
                  className={
                    this.isPathActive("/tracker")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/tracker"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Trackers</span>
                  </Link>
                </li>
              )}

              {global.userPermissions.checkPermission("audits-list") && (
                <h2 className="menu_heading">AUDITS</h2>
              )}
              {global.userPermissions.checkPermission("template-list") && (
                <li
                  className={
                    this.isPathActive("/audit-templates") ||
                    this.isPathActive("/audit-template/update") ||
                    this.isPathActive("/audit-template/add") ||
                    this.isPathActive("/audit-perform") ||
                    this.isPathActive("/start-audit-list")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/audit-templates"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Audit Templates</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("audits-list") && (
                <li
                  className={
                    this.isPathActive("/audits") ||
                    this.isPathActive("/audit-resume")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/audits"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>AUDITS </span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("action_items-list") && (
                <li
                  className={
                    this.isPathActive("/action-item")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/action-item"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Action items</span>
                  </Link>
                </li>
              )}

              {global.userPermissions.checkPermission("quick-link-list") && (
                <>
                  <h2 className="menu_heading">Setting</h2>
                  <li
                    className={
                      this.isPathActive("/all-quick-links")
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Link
                      to="/all-quick-links"
                      className="nav-link nav-link-custom d-flex"
                    >
                      <span>Quick Links </span>
                    </Link>
                  </li>
                </>
              )}

              <h2 className="menu_heading">MASTER DATA</h2>
              {global.userPermissions.checkPermission("brand-list") && (
                <li
                  className={
                    this.isPathActive("/brands")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/brands"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Brands </span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("property-list") && (
                <li
                  className={
                    this.isPathActive("/properties")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/properties"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Properties</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission(
                "checklist-templates-list"
              ) && (
                <li
                  className={
                    this.isPathActive("/templates")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/templates"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>INSPECTION TEMPLATES</span>
                  </Link>
                </li>
              )}

              {global.userPermissions.checkPermission("manage-link-list") && (
                <li
                  className={
                    this.isPathActive("/quick-links")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/quick-links"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Manage Links</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("user-list") && (
                <li
                  className={
                    this.isPathActive("/user") ? "nav-item active" : "nav-item"
                  }
                >
                  <Link to="/user" className="nav-link nav-link-custom d-flex">
                    <span>Users</span>
                  </Link>
                </li>
              )}

              {global.roleTypeId === 1 ? (
                <li
                  className={
                    this.isPathActive("/roles") ||
                    this.isPathActive("/role-permission/")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/roles" className="nav-link nav-link-custom d-flex">
                    <span>Roles</span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              <li
                className={
                  this.isPathActive("/update-profile")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  to="/update-profile"
                  className="nav-link nav-link-custom d-flex"
                >
                  <span>My Account</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

export default withRouter(Header);
