/*
 * Program: UserForm.jsx
 * Application: User
 * Option: to Add/Update User Information
 * Developer: Chandra Shekhar
 * Date: 2022-03-31
 */
import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import CustomerService from "../../services/CustomerService";
import Alerts from "../common/Alerts";
import UserService from "../../services/UserService";
import PropertyService from "../../services/PropertyService";
import Select from "react-select";
// import modaldeleteicon from "./../../assets/images/delete.svg";
import back from "./../../assets/images/back.svg";

export class UserForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      isDelete: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      slug: this.props.match.params.slug,
      isSubmit: false,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      job_title: "",
      role_id: "",
      status_id: true,
      is_blocked: false,
      customer_slug: "",
      customerList: [],
      selectedCustomer: [],
      selectedCustomerList: [],
      selectStatusList: [],
      selectRoleList: [],
      property_slug: [],
      propertyList: [],
      selectedProperties: [],
      selectedPropertyList: [],
      propertyListTotal: 0,
      hideCustomerList: 0,
      hidePropertyList: 0,
      allSelectedCustomers: [],
      allSelectedProperties: [],
      updateSelectedPropertyList: [],
      updateSelectedPropertyListTotal: 0,
      updateSelectedCustomerSlugs: [],
      selectedRoleList: [],
      previousPropertySlug: [],
    };
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateUser = this.saveOrUpdateUser.bind(this);
    this.UpdateUserBlocked = this.UpdateUserBlocked.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.opendeleteUserModal = this.opendeleteUserModal.bind(this);
    this.inviteUser = this.inviteUser.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await UserService.getUser(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.status = res.data.status === 1 ? true : false;
        resData.status_id = res.data.status_id === 1 ? true : false;
        resData.is_blocked = res.data.attemptData ? res.data?.attemptData?.is_blocked : "";
        let customerSlugs = [];
        if (res.data.customers !== undefined) {
          var selectedCustomer = [];
          res.data.customers.forEach((customer, i) => {
            selectedCustomer.push({
              value: customer.slug,
              label: customer.customer_name,
            });
            customerSlugs.push(customer.slug);
          });
          this.setState({ selectedCustomerList: selectedCustomer });
        }
        let propertySlugs = [];
        if (res.data.properties !== undefined) {
          const selectedProperties = [];
          resData.properties.forEach((property, i) => {
            selectedProperties.push({
              value: property.slug,
              label: property.property_name,
            });
            propertySlugs.push(property.slug);
          });
          this.setState({ selectedPropertyList: selectedProperties });
          this.setState({
            propertyListTotal: resData.properties.length,
            allSelectedProperties: resData.properties,
          });
        }

        if (resData.role_id === 2) {
          this.setState({ hideCustomerList: 1 });
          this.setState({ hidePropertyList: 0 });
          resData.customer_slug = customerSlugs ? customerSlugs : "";
        } else if (resData.role_id === 3 || resData.role_id === 5 || resData.role_id === 6) {
          resData.customer_slug = customerSlugs ? customerSlugs : "";
          resData.property_slug = propertySlugs ? propertySlugs : "";
          this.getPropertyData(customerSlugs);
          this.setState({ hideCustomerList: 1 });
          this.setState({ hidePropertyList: 1 });
        } else if (resData.role_id === 4) {
          resData.property_slug = propertySlugs ? propertySlugs : "";
          this.getPropertyData("&property_user=1");
          this.setState({ hideCustomerList: 0 });
          this.setState({ hidePropertyList: 1 });
        } else {
          this.setState({ hideCustomerList: 0 });
          this.setState({ hidePropertyList: 0 });
        }
        var selectedRoleType = [];
        selectedRoleType = res.data
          ? [
              {
                value: res.data.role_id,
                label: res.data.role_type_name,
              },
            ]
          : "";
        this.setState({
          selectedRoleList: selectedRoleType,
        });
      }
      this.setState(resData);
      this.defaultData = resData;
    }
    this.getRoleList();
    this.getCustomerList();
  }

  saveOrUpdateUser = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ isSubmit: true });
      let inputData = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone,
        job_title: this.state.job_title,
        status_id: this.state.status_id,
        role_id: this.state.role_id,
        customer_slug: this.state.customer_slug,
        property_slug: this.state.property_slug,
      };
      if (this.state.slug !== undefined) {
        let res = await UserService.updateUser(this.state.slug, inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/user"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";

          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage;
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        let res = await UserService.createUser(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/user"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage;
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  UpdateUserBlocked = async (e) => {
    e.preventDefault();
      this.setState({ isSubmit: true });
      this.setState((prevState) => ({
        is_blocked: !prevState.is_blocked, // toggle the state
      }));
      let inputData = {
        user_token : this.state.slug,
      };
      if (this.state.slug !== undefined) {
        let res = await UserService.updateUserBlocked(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
        } else {
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage: res.message ? res.message : "Success",
          });
        }
      } 
      this.setState({ isSubmit: false});
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time 
      );
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    if (event.target.name === "phone") {
      let number = event.target.value;
      if (!Number(number) && number) {
        return;
      }
      this.setState({
        [event.target.name]: number,
      });
    }
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    if (event.target.name === "customer_slug") {
      this.getPropertyData(event.target.value);
    }
  };

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
  }

  // Customer Change Handler
  handleChangeCustomerList(value) {
    let selected = this.state.selectedCustomerList;
    let difference = [];
    if (selected !== null && selected.length > 0 && value !== null) {
      difference = selected.filter((x) => !value.includes(x)); // calculates diff
    }

    let customerSlugs = [];
    let allCustomerSlugs = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        this.state.allSelectedCustomers.forEach((allCustomerList, j) => {
          if (allCustomerList.slug === item.value) {
            allCustomerSlugs.push(item.value);
          }
        });
        customerSlugs.push(item.value);
      });
    } else {
      this.setState({ property_slug: [] });
    }

    let updateSelectedPropertyList1 = this.state.updateSelectedPropertyList;
    let newupdateSelectedPropertyList1 = [];
    allCustomerSlugs.map((item) => {
      updateSelectedPropertyList1.map((item1) => {
        if (item === item1.customer_slug) {
          newupdateSelectedPropertyList1.push(item1);
        }
      });
    });

    let propertyListData = this.state.selectedPropertyList;
    if (difference.length > 0) {
      difference.map((itm1) => {
        propertyListData = [];
        newupdateSelectedPropertyList1.map((itm, i) => {
          if (itm.customer_slug === itm1.value) {
            //
          } else {
            if(this.state.previousPropertySlug.includes(itm.slug)){
              propertyListData.push({
                value: itm.slug,
                label: itm.property_name,
              });
            }
          }
        });
      });
    }

    if (value === null) {
      propertyListData = [];
    }
    this.setState({
      selectedCustomerList: value,
      customer_slug: customerSlugs,
      updateSelectedCustomerSlugs: allCustomerSlugs,
      selectedPropertyList: propertyListData,
    });
    this.getPropertyData(customerSlugs);
  }
  opendeleteAlertModal() {
    this.setState({ alertModal: true });
  }
  handleChangeRole(value) {
    this.validator.hideMessages();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    if (value != null) {
      this.setState({
        customerList: [],
        selectedCustomerList: [],
        customer_slug: [],
      });
      this.setState({
        propertyList: [],
        selectedPropertyList: [],
        property_slug: [],
      });
      if (value.value !== "1") {
        this.getCustomerList();
      }
      /* use to hide show the dropdown list start */
      if (value.value === "2") {
        this.setState({ hideCustomerList: 1 });
        this.setState({ hidePropertyList: 0 });
      } else if (value.value === "3" || value.value === "5" || value.value === "6") {
        this.setState({ hideCustomerList: 1 });
        this.setState({ hidePropertyList: 1 });
      } else if (value.value === "4") {
        this.setState({ hideCustomerList: 0 });
        this.setState({ hidePropertyList: 1 });
        this.getPropertyData("&property_user=1");
      } else {
        this.setState({ hideCustomerList: 0 });
        this.setState({ hidePropertyList: 0 });
      }
      /* use to hide show the dropdown list end*/
      this.setState({
        selectedRoleList: value,
        role_id: value.value,
      });
    }
  }
  // Properties Change Handler
  handleChangePropertyList(value) {
    let propertySlugs = [];
    let allPropertySlugs = [];
    // if (this.state.customer_slug.length === 0 && this.state.role_id !== '4') {
    //   this.opendeleteAlertModal();
    //   return false;
    // }
    if (this.state.customer_slug.length === 0 && (this.state.role_id === '2' || this.state.role_id === '3' || this.state.role_id === '5' || this.state.role_id === '6' )) {
      this.opendeleteAlertModal();
      return false;
    }
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        this.state.allSelectedProperties.forEach((allPropertyList, j) => {
          if (allPropertyList.slug === item.value) {
            allPropertySlugs.push(allPropertyList);
          }
        });
        propertySlugs.push(item.value);
      });
    }

    this.setState({
      selectedPropertyList: value,
      property_slug: propertySlugs,
      previousPropertySlug: propertySlugs,
      updateSelectedPropertyList: allPropertySlugs,
      updateSelectedPropertyListTotal: allPropertySlugs.length,
    });
  }
  customValidate() {
    let isValid = true;
    return isValid;
  }

  opendeleteUserModal(slug) {
    this.setState({ deleteUserModal: true });
  }

  closedeleteUserModal() {
    this.setState({ deleteUserModal: false });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }
  closeAlertModal() {
    this.setState({ alertModal: false });
  }
  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update User";
    } else {
      return "Add User";
    }
  }

  getRoleList() {
    const roleList = global.selectRoleList;
    let roles = [];
    if (roleList != null && roleList.length > 0) {
      roleList.forEach((item, i) => {
        if (global.roleTypeId !== 1) {
          if (item.value > global.roleTypeId) {
            roles.push({
              value: item.value,
              label: item.name,
            });
          }
        } else {
          roles.push({
            value: item.value,
            label: item.name,
          });
        }
      });
    }
    this.setState({ selectRoleList: roles });
  }

  // Get Customer List
  async getCustomerList(queryString = "") {
    this.setState({ loading: true });
    var customerList = [];
    var customerListData = [];
    let res = await CustomerService.getCustomerList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      customerList = res.data ? res.data : [];
    }
    this.setState({
      customerList: customerList,
      allSelectedCustomers: customerList,
    });
    customerList.forEach((item) => {
      customerListData.push({
        label: item.customer_name,
        value: item.slug,
        valuep: item.slug,
      });
    });
    this.setState({ customerList: customerListData });
    this.setState({ loading: false });
  }

  async getPropertyData(customerSlug = "", queryString = "") {
    this.setState({ loading: true });
    var propertyList = [];
    var propertyListData = [];
    var propertyListCount = 0;
    let res = await PropertyService.getPropertyByCustomer(
      "",
      (queryString = "customer_slug=" + customerSlug)
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListCount = propertyList.length;
    }
    this.setState({
      propertyListTotal: propertyListCount,
      updateSelectedPropertyList: propertyList,
    });
    if (this.state.propertyListTotal > 0) {
      propertyList.forEach((item) => {
        propertyListData.push({
          value: item.slug,
          label: item.property_name,
          customer_slug: item.customer_slug,
        });
      });
    }
    this.setState({
      propertyList: propertyListData,
      allSelectedProperties: propertyList,
    });
    this.setState({ loading: false });
  }

  async deleteUser(slug) {
    this.setState({ loading: true, isDelete: true });
    let res = await UserService.deleteUser(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteUserModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(() => this.props.history.push("/user"), global.redirect_time);
    } else {
      this.closedeleteUserModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isDelete: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async inviteUser(slug) {
    this.setState({ loading: true, isSubmit: true });
    let res = await UserService.resentInvitation(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(() => this.props.history.push("/user"), global.redirect_time);
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };

    const DropDown = (props) => {
      const options = props.multi
        ? [{ label: "Select All", value: "all" }, ...props.options]
        : props.options;
      return (
        <Select
          className="multiselect new_drop"
          menuPlacement="auto"
          autoComplete="off"
          options={options}
          isMulti={props.multi}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary75: "#808080",
              primary50: "#808080",
              primary25: "#808080",
            },
          })}
          getOptionValue={(option) => `${option.label}`}
          closeMenuOnSelect={false}
          value={props.value ? props.value : null}
          defaultValue={this.state.selectedCustomerList}
          onChange={(selected) => {
            if (selected !== null) {
              selected.find((option) => option.value === "all")
                ? props.onChange(options.slice(1))
                : !props.multi
                ? props.onChange((selected && selected.value) || null)
                : props.onChange(selected);
            } else {
              props.onChange(selected);
            }
          }}
        />
      );
    };

    const DropDownProprty = (props) => {
      const options = props.multi
        ? [{ label: "Select All", value: "all" }, ...props.options]
        : props.options;
  // const options = props.options && props.options.length > 0
  //   ? [{ label: "Select All", value: "all" }, ...props.options]
  //              : [{ label: "No options", value: "no_options" }]
      return (
        <Select
          className="multiselect new_drop"
          menuPlacement="auto"
          autoComplete="off"
          options={options}
          isMulti={props.multi}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary75: "#808080",
              primary50: "#808080",
              primary25: "#808080",
            },
          })}
          getOptionValue={(option) => `${option.label}`}
          closeMenuOnSelect={false}
          value={props.value ? props.value : null}
          defaultValue={this.state.selectedPropertyList}
          onChange={(selected) => {
            if (selected !== null) {
              selected.find((option) => option.value === "all")
                ? props.onChange(options.slice(1))
                : !props.multi
                ? props.onChange((selected && selected.value) || null)
                : props.onChange(selected);
            } else {
              props.onChange(selected);
            }
          }}
        />
      );
    };
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle heading_alignment">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>USER</h3>
                        <div className="btnNavigate d-flex justify-content-end">
                          <Link to="/user">
                            <img src={back} alt="" />
                            Back
                          </Link>
                        </div>
                      </div>
                      <span>
                        {this.state.slug !== undefined ? (
                          <>Update User</>
                        ) : (
                          <>Add User</>
                        )}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 pl-0 pr-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>
            <div className="col-md-12 mg-t-0 p-5 dashboardRightcard ">
              <div className="tabNavigation">
                <form id="register_from">
                  <div className="m-0">
                    <div className="">
                      <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                        <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                          <div className="row align-items-basline flex-wrap m-0">
                            {/* First Name */}
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0">
                              <label className="form-label text-left mg-b-5">
                                First Name{" "}
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="first_name"
                                value={this.state.first_name}
                                maxLength="50"
                                tabIndex="1"
                                autoComplete="off"
                                onKeyPress={
                                  global.onKeyPressEvent.alphaSpaceValidation
                                }
                              />
                              {this.state.errors.first_name ? (
                                <div className="text-danger">
                                  {this.state.errors.first_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "first_name",
                                  this.state.first_name,
                                  "required|max:50",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Last Name */}
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0">
                              <label className="form-label text-left mg-b-5">
                                Last Name
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="last_name"
                                value={this.state.last_name}
                                maxLength="50"
                                tabIndex="2"
                                autoComplete="off"
                                onKeyPress={
                                  global.onKeyPressEvent.alphaSpaceValidation
                                }
                              />
                              {this.state.errors.last_name ? (
                                <div className="text-danger">
                                  {this.state.errors.last_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "last_name",
                                  this.state.last_name,
                                  "required|max:50",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Phone Munber */}
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0">
                              <label className="form-label text-left mg-b-5">
                                Number
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="phone"
                                value={this.state.phone}
                                maxLength="12"
                                tabIndex="3"
                                autoComplete="off"
                              />
                              {this.state.errors.phone ? (
                                <div className="text-danger">
                                  {this.state.errors.phone}
                                </div>
                              ) : (
                                this.validator.message(
                                  "number",
                                  this.state.phone,
                                  "required|max:12|phone",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Email */}
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0">
                              <label className="form-label text-left mg-b-5">
                                Email
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="email"
                                value={this.state.email}
                                maxLength="100"
                                tabIndex="4"
                                autoComplete="off"
                              />
                              {this.state.errors.email ? (
                                <div className="text-danger">
                                  {this.state.errors.email}
                                </div>
                              ) : (
                                this.validator.message(
                                  "email",
                                  this.state.email,
                                  "required|email|max:100",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Status */}
                            {global.userPermissions.checkPermission("user-update") && (
                            <div className="mg-r-20 mg-t-5 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0 d-flex align-items-center justify-content-start">
                              <label className="form-label mg-b-0">
                                {this.state.status_id ? "Active" : "Inactive"}{" "}
                              </label>
                              <div
                                className={
                                  this.state.status_id
                                    ? "az-toggle on"
                                    : "az-toggle"
                                }
                                onClick={() =>
                                  this.setState({
                                    status_id: !this.state.status_id,
                                  })
                                }
                              >
                                <span></span>
                              </div>
                            </div>)}
                            {this.state.is_blocked ? (
                            <div className="mg-r-20 mg-t-5 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0 d-flex align-items-center justify-content-start blocked_user">
                              <label className="form-label mg-b-0">
                                {this.state.is_blocked ? "Blocked" : "Unblocked"}{" "}
                              </label>
                              <div
                                className={
                                  this.state.is_blocked
                                    ? "az-toggle on"
                                    : "az-toggle"
                                }
                                onClick={this.UpdateUserBlocked}
                              >
                                <span></span>
                              </div>
                            </div>) : ""}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                          <div className="row align-items-basline flex-wrap m-0">
                            {/* Job Title */}
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0">
                              <label className="form-label text-left mg-b-5">
                                Job Title (Optional)
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="job_title"
                                value={this.state.job_title}
                                maxLength="50"
                                tabIndex="5"
                                autoComplete="off"
                                // onKeyPress={
                                //   global.onKeyPressEvent.alphaSpaceValidation
                                // }
                              />
                            </div>
                            {/* Role */}
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0">
                              <label className="form-label text-left mg-b-5">
                                Role
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedRoleList}
                                options={this.state.selectRoleList}
                                onChange={(value) =>
                                  this.handleChangeRole(value)
                                }
                                defaultValue={this.state.selectedRoleList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                              {this.state.errors.role_id ? (
                                <div className="text-danger">
                                  {this.state.errors.role_id}
                                </div>
                              ) : (
                                this.validator.message(
                                  "role",
                                  this.state.role_id,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {this.state.hideCustomerList === 1 ? (
                              <>
                                {/* Customer Admn */}
                                <div className="col-md-12 col-lg-12 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0">
                                  <label className="form-label text-left mg-b-5">
                                    Brand Name{" "}
                                  </label>
                                  <DropDown
                                    options={this.state.customerList}
                                    onChange={(value) =>
                                      this.handleChangeCustomerList(value)
                                    }
                                    multi={true}
                                    value={this.state.selectedCustomerList}
                                  />
                                  {this.state.errors.customer_slug ? (
                                    <div className="text-danger">
                                      {this.state.errors.customer_slug}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "brand",
                                      this.state.customer_slug,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {/* Property List */}
                            {this.state.hidePropertyList === 1 ? (
                              <>
                                <div className="col-md-12 col-lg-12 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0 prop_name">
                                  <label className="form-label text-left mg-b-5">
                                    Property Name{" "}
                                  </label>
                                  <DropDownProprty
                                    options={this.state.propertyList}
                                    onChange={(value) =>
                                      this.handleChangePropertyList(value)
                                    }
                                    multi={true}
                                    value={this.state.selectedPropertyList}
                                  />

                                  {this.state.errors.property_slug ? (
                                    <div className="text-danger">
                                      {this.state.errors.property_slug}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "property",
                                      this.state.property_slug,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row row-xs align-items-center flex-wrap m-0 pd-t-30 pd-b-0 pd-l-80 pd-r-80">
                        <div className="col-md-6 btnNavigate d-flex align-items-center flex-wrap-bothBtn p-0">
                          <div className="d-flex align-items-center">
                            {this.state.slug !== undefined ? (
                              <>
                              {global.userPermissions.checkPermission("user-delete") && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.opendeleteUserModal(this.state.slug);
                                  }}
                                  className="detlet-text mg-r-20"
                                >
                                  Delete User
                                </button> )}
                              </>
                            ) : (
                              ""
                            )}
                            {this.state.slug !== undefined && this.state.status_id !== false ? (
                              <>
                              {global.userPermissions.checkPermission("user-update") && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.inviteUser(this.state.slug);
                                  }}
                                  className="detlet-text mg-r-20"
                                  // disabled={!this.state.status_id}
                                  >
                                  RESEND INVITE
                                </button>)}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 btnNavigate d-flex align-items-center justify-content-between flex-row-reverse flex-wrap-bothBtn p-0">
                          <div className="d-flex align-items-center">
                            <Link to="/user">
                              <button
                                tabIndex="9"
                                className="gf-btn-lg-outline mg-r-7"
                                type="button"
                              >
                                Cancel
                              </button>
                            </Link>
                            { this.state.slug == undefined && global.userPermissions.checkPermission("user-add") && (
                            <button
                              tabIndex="10"
                              className="gf-btn-lg-stroke mg-l-2"
                              type="button"
                              onClick={this.saveOrUpdateUser}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                :  "Add"}
                            </button>)}
                            { this.state.slug !== undefined && global.userPermissions.checkPermission("user-update") && (
                            <button
                              tabIndex="10"
                              className="gf-btn-lg-stroke mg-l-2"
                              type="button"
                              onClick={this.saveOrUpdateUser}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : "Update"}
                            </button>)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
        <Modal show={this.state.deleteUserModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Delete User?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Do you really want to delete this User? This process cannot be
                undone.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteUserModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteUser(this.state.slug)}
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
        show={this.state.alertModal}
        centered
      >
        <Modal.Body className="width_402 p-4 border_radius_14">
          <div className="text-center">
            {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
            <h3>Alert Message?</h3>
            <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
              Please select brand name first.
            </p>
            <div className="d-flex cnt_center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAlertModal()}
              >
                Ok
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </div>
    );
  }
}
export default UserForm;
