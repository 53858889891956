import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import ChecklistService from "../../services/ChecklistService";
import placeholderImage from "./../../assets/images/image-placeholder.png";
import UserService from "../../services/UserService";
import MroCategoryService from "../../services/MroCategoryService";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import Moment from "moment";
import InspectionService from "../../services/InspectionService";
import imageCompression from "browser-image-compression";
import Alerts from "../common/Alerts";
import { Link } from "react-router-dom";
import back from "./../../assets/images/back.svg";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import CustomToolbar from '../CustomToolbar';
import LinkModal from '../LinkModal';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color:
      state.isSelected && state.isFocused
        ? "white"
        : state.isSelected
        ? "black"
        : state.isFocused && "white",
    backgroundColor:
      state.isSelected && state.isFocused ? "gray" : state.isFocused && "gray",
    fontFamily: "Roboto-Regular",
    fontSize: "16px",
  }),
};
class App extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.quillRef = React.createRef();
    this.state = {
      showModal: false,
      range: null,
      url: '',
      text: '',
      isEditing: false,
      errors: {},
      input: {},
      isSubmit: false,
      isLoading: false,
      showAlertModal: false,
      showConfirmModel: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      checklist_slug: this.props.match.params.checklist_slug,
      property_slug: this.props.match.params.property_slug,
      zone_type: this.props.match.params.zone_type,
      steps: [
        {
          key: "firstStep",
          label: "Info",
          isDone: true,
          component: this.firstComponent,
        },
        {
          key: "secondStep",
          label: "Inspect",
          isDone: false,
          component: this.secondComponent,
        },
        {
          key: "finalStep",
          label: "Review",
          isDone: false,
          component: this.thirdComponent,
        },
      ],
      activeStep: {
        key: "firstStep",
        label: "Info",
        isDone: true,
        component: this.firstComponent,
      },
      propertyList: [],
      zoneTypeList: [],
      locationName: "",
      is_open: 0,
      showBasicModal: false,
      isCreateMroChecked: false,
      isCreateMroCheckedBoxShow: false,
      priorityType: global.priorityType,
      selectedPriorityType: [],
      mroCategory: [],
      selectedMroCategory: [],
      userList: [],
      selectedUserList: [],
      in_time: Moment(new Date()).toDate("dd-MM-YYYY | hh:mm A"),
      out_time: "",
      is_image: false,
      login_user: global.userData.full_name,
      note: undefined,
      mroCreated: false,
      isImage: false,
      arrAllImages: [],
    };
    this.bindMethods();
  }
  bindMethods() {
    this.closeModal = this.closeModal.bind(this);
    this.submitModalforNote = this.submitModalforNote.bind(this);
    this.saveOrUpdateInspection = this.saveOrUpdateInspection.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleDueDate = this.handleDueDate.bind(this);
    this.handleChangeUserList = this.handleChangeUserList.bind(this);
    this.handleChangePriority = this.handleChangePriority.bind(this);
    this.handleChangeMroCategory = this.handleChangeMroCategory.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.changeHandlerForNote = this.changeHandlerForNote.bind(this);
    this.imagePreview = this.imagePreview.bind(this);
    this.handleAnswerOptionClick = this.handleAnswerOptionClick.bind(this);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.checkInspectionAvailableOnChecklistInLocal =
      this.checkInspectionAvailableOnChecklistInLocal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleModalRemove = this.handleModalRemove.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
  }
  async componentDidMount() {
    const { checklist_slug } = this.state;
    if (checklist_slug !== undefined) {
      await Promise.all([this.getLocation()]);

      const localInsp = localStorage.getItem("localInsp") || "";
      if (localInsp != "" && localInsp != undefined) {
        /* conver into the object */
        var localInspObj = JSON.parse(localInsp);

        /* Check the uniq key in the array */
        const newKey = `${global.userData.slug}/${this.state.property_slug}/${this.state.checklist_slug}`;

        // find the index of unique key
        const index = localInspObj
          ? localInspObj.findIndex((x) => x.uniqueKey === newKey)
          : -1;
        if (index !== -1) {
          var pendingInsp = localInspObj[index];
        }
        this.setState({
          showConfirmModel: false,
          category: pendingInsp ? pendingInsp.category : "",
          checklist_name_heading: pendingInsp
            ? pendingInsp.checklist_name_heading
            : "",
          checklist_slug: pendingInsp ? pendingInsp.checklist_slug : "",
          property_inspection_perform: pendingInsp
            ? pendingInsp.property_inspection_perform
            : "",
          property_slug: pendingInsp ? pendingInsp.property_slug : "",
          zone_name_heading: pendingInsp ? pendingInsp.zone_name_heading : "",
          uniqueKey: pendingInsp ? pendingInsp.uniqueKey : "",
        });

        if (index == -1) {
          this.checklistDetail(checklist_slug);
        }
      }
    }
    // setTimeout(() => {
    //   if (this.quillRef.current) {
    //     const quill = this.quillRef.current.getEditor();
    //     if (quill) {
    //       quill.getModule('toolbar').addHandler('link', this.handleLink);
    //       // Add event listener for clicks on links
    //       quill.root.addEventListener('click', this.handleLinkClick);
    //     } else {
    //       console.error('Quill editor is not initialized');
    //     }
    //   } else {
    //     console.error('quillRef.current is null');
    //   }
    // }, 100); // The delay can be adjusted if necessary
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.quillRef.current && !prevState.note && this.state.note) {
      const quill = this.quillRef.current.getEditor();
      if (quill) {
        quill.getModule('toolbar').addHandler('link', this.handleLink);
        // Add event listener for clicks on links
        quill.root.addEventListener('click', this.handleLinkClick);
      }
    }
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }
  closeModal() {
    this.setState({ showBasicModal: false });
  }

  async getLocation() {
    var locationList = [];
    var locationListData = [];
    var queryString =
      "is_web=1&filter_value=&property_slug=" +
      this.state.property_slug +
      "&zone_type=" +
      this.state.zone_type;
    let res = await ChecklistService.getLocationZoneAndPropertyWise(
      queryString
    );
    if (global.successStatus.includes(res.status)) {
      locationList = res.data ? (res.data.data ? res.data.data : res.data) : [];
    }
     // Ensure locationList is an array
     if (Array.isArray(locationList)) {
      locationList.forEach((item) => {
        locationListData.push({
          label: item.name,
          value: item.slug,
        });
      });
    } else {
      console.error('locationList is not an array:', locationList);
    }
    this.setState({ locationList: locationListData });
  }
  // Location Change Handler
  handleChangeLocation(value) {
    this.setState({
      selecteLocationList: value,
      location_slug: value.value,
      location_name: value.label,
    });
    this.setState({ confirm_back: 1 });
  }
  firstComponent = () => {
    return (
      <div>
        <div className="row row-xs">
        {this.state.description ?
            <div>
              <label className="form-label text-left mb-0">
                Template Description
              </label>
              <p className="text-left mb-2">
                {this.state.description ? this.state.description : ""}
              </p>
            </div>
          : ""}
          <div className="col-md-12 mg-b-10">
            <h4 className="form-label text-left mg-b-0">
              {" "}
              {this.state.checklist_name_heading}
            </h4>
            <span className="category_name ">
              {" "}
              <strong>{this.state.zone_name_heading}</strong>
            </span>
          </div>
          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
            <label className="form-label mg-b-5">Location</label>

            <Select
              className="multiselect new_drop"
              menuPlacement="auto"
              value={this.state.selecteLocationList}
              options={this.state.locationList}
              onChange={(value) => this.handleChangeLocation(value)}
              defaultValue={this.state.selecteLocationList}
              autoComplete="off"
              getOptionValue={(option) => `${option.label}`}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary75: "#808080",
                  primary50: "#808080",
                  primary25: "#808080",
                },
              })}
            />
            {this.state.location_slug == undefined ? (
              <>
                <div className="text-danger">
                  {this.state.location_slug_error}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
            <label className="form-label mg-b-5">In-Time</label>
            <DatePicker
              className="form-control max_width_100"
              showMonthDropdown
              showYearDropdown
              // scrollableYearDropdown
              autoComplete="off"
              yearDropdownItemNumber={global.yearDropdownItemNumber}
              name="in_time"
              placeholderText="mm-dd-yyyy"
              dateFormat="dd MMM yyyy | hh:mm a"
              selected={this.state.in_time}
              onChange={(data) =>
                this.changeHandler({
                  target: {
                    type: "date",
                    name: "in_time",
                    value: data,
                  },
                })
              }
            />
          </div>
          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
            <label className="form-label mg-b-5">Technician</label>
            <Form.Control
              name="user_slug"
              className="form-control max_width_100"
              type="text"
              value={this.state.login_user}
              readOnly
            />
          </div>
        </div>
      </div>
    );
  };
  /* *************************************** END FIRST COMPONENT ******************************* */
  // Get Checklist data
  async checklistDetail(queryString = "") {
    var resData = [];
    let res = await ChecklistService.getChecklistRowBySlug(queryString);
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data.data : [];
      resData.property_name = resData.property
        ? resData.property.property_name
        : [];
      let newCategory = resData.category.map((category) => {
        category["is_selected_category"] = 0;
        category.task_name.map((task) => {
          task["is_selected"] = 0;
          task["is_mro_with_note"] = 0;
          task["note_with_mro_slug"] = null;
          task["note"] = null;
          task["mro_category"] = null;
          task["mro_category_slug"] = null;
          task["mro_priority"] = null;
          task["mro_priority_id"] = null;
          task["mro_technician"] = null;
          task["mro_technician_slug"] = null;
          task["zone_type"] = null;
          task["zone_type_name"] = null;
          task["due_date"] = null;
          task["location_slug"] = null;
          task["location_name"] = null;
          task["checked"] = false;
          task["images"] = [];
          return task;
        });
        return category;
      });
      resData.category = newCategory ? newCategory : "";
    } else {
      let alertMessage = "";
      if (res.data.result.errorDetail !== null) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }

    this.setState(resData);
    this.setState({
      locationName: this.state.selecteLocationList
        ? this.state.selecteLocationList.label
        : "",
    });
    this.setState({ category: resData.category });
    this.setState({
      property_inspection_perform: resData.property.property_name,
    });
    this.setState({ checklist_name_heading: resData.checklist_name });
    this.setState({ zone_name_heading: resData.zone.zone_name });
    this.setState({ checklist_slug: resData.slug });
    this.setState({ property_slug: resData.property.slug });
  }
  /* click on task checkbox */
  handleCheckboxClick(e, index, indexes) {
    // e.preventDefault();
    let CheckChecked = e.target.checked;
    let category = this.state.category;
    let currentCategory = this.state.category[index];
    let curentTask = currentCategory.task_name[indexes];
    if (CheckChecked === true) {
      currentCategory.is_selected_category = 1;
      curentTask.is_open = 1;
      curentTask.is_selected = 1;
    } else {
      currentCategory.is_selected_category = 0;
      curentTask.is_open = 0;
      curentTask.is_selected = 0;
      curentTask.task_status = null;
    }
    // this.setState({ category: category, mroCreated: isMro });
    this.setState({ category: category });
    this.localDataStorage(this.state.category);
  }
  /* YES NO NOTE ANSWER HANDLER */
  handleAnswerOptionClick(ev, index, indexes) {
    ev.preventDefault();
    let nameOfEvent = ev.target.value;
    let category = this.state.category;
    if (nameOfEvent === "YES") {
      category[index].task_name[indexes].task_status = 1;
      this.setState({ category: category });
      this.localDataStorage(this.state.category);
    }
    if (nameOfEvent === "NO") {
      category[index].task_name[indexes].task_status = 2;
      this.setState({ category: category });
      this.localDataStorage(this.state.category);
    }
    if (nameOfEvent === "NOTE") {
      this.mroAndNoteDataSet(category, index, indexes);
      this.setState({
        showBasicModal: true,
        index: index,
        indexes: indexes,
      });
    }
  }

  /* Set mro and note data */
  mroAndNoteDataSet(category, index, indexes) {
    var selectedPriorityType = {
      label: category[index].task_name[indexes].mro_priority ?? null,
      value: category[index].task_name[indexes].mro_priority_id ?? null,
    };
    var selectedUserList = {
      label: category[index].task_name[indexes].mro_technician ?? null,
      value: category[index].task_name[indexes].mro_technician_slug ?? null,
    };
    var selectedMroCategory = {
      label: category[index].task_name[indexes].mro_category ?? null,
      value: category[index].task_name[indexes].mro_category_slug ?? null,
    };
    var selecteLocationList = {
      label: category[index].task_name[indexes].location_name ?? null,
      value: category[index].task_name[indexes].location_slug ?? null,
    };
    var selectedZoneTypeList = {
      label: category[index].task_name[indexes].zone_type_name ?? null,
      value: category[index].task_name[indexes].zone_type ?? null,
    };
    var newDate =
      category[index].task_name[indexes].due_date != undefined
        ? Moment(category[index].task_name[indexes].due_date).toDate(
            "dd-MM-YYYY | hh:mm A"
          )
        : "";
    this.setState({
      task_name: category[index].task_name[indexes].task_name,
      task_slug: category[index].task_name[indexes].slug,
      property_name:
        this.state.property_name ?? this.state.property_inspection_perform,
      note: category[index].task_name[indexes].note,
      checked: category[index].task_name[indexes].checked,
      selectedPriorityType: selectedPriorityType,
      selectedUserList: selectedUserList,
      due_date: newDate,
      selectedMroCategory: selectedMroCategory,
      selecteLocationList:
        selecteLocationList ?? this.state.selecteLocationList,
      selectedZoneTypeList: selectedZoneTypeList,
      arrAllImages: category[index].task_name[indexes].images,
    });
  }

  /* handle mro and node submission */
  submitModalforNote(e) {
    e.preventDefault();
    var category = this.state.category;
    var index = this.state.index;
    var indexes = this.state.indexes;

    if (this.state.checked === false) {
      if (this.state.note != undefined) {
        category[index].task_name[indexes].is_mro_with_note = 2;
        category[index].task_name[indexes].checked = false;
        this.mroAndNoteDataSet(category, index, indexes);
        this.setState({
          showBasicModal: false,
          noteMessage: "",
        });
      } else {
        this.setState({
          noteMessage: "The note is required.",
          showBasicModal: true,
        });
      }
    }

    if (this.state.checked === true) {
      if (
        this.state.mro_category_slug != undefined &&
        this.state.mro_priority_id != undefined &&
        this.state.mro_technician_slug != undefined &&
        this.state.due_date != undefined
      ) {
        // if ( Object.keys(this.state.selectedMroCategory).length > 0 && Object.keys(this.state.selectedPriorityType).length > 0 && Object.keys(this.state.selectedUserList).length >0) {
        if (category[index].task_name[indexes].checked === true) {
          category[index].task_name[indexes].is_mro_with_note = 1;
          category[index].task_name[indexes].checked = true;

          this.mroAndNoteDataSet(category, index, indexes);
          this.setState({
            showBasicModal: false,
            priority_type_error: "",
            user_slug_error: "",
            due_date_error: "",
            mro_category_slug_error: "",
          });
        }
      } else {
        this.setState({
          priority_type_error: "The priority field is required.",
          user_slug_error: "The user field is required.",
          due_date_error: "The due date is required.",
          mro_category_slug_error: "The mro category is required.",
          showBasicModal: true,
        });
      }
    }
    this.localDataStorage(category);
  }

  handleLink() {
    const quill = this.quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
      this.setState({ showModal: true, range, url: '', text: '', isEditing: false });
    }
  }

  handleModalClose() {
    this.setState({ showModal: false, range: null, url: '', text: '', isEditing: false });
  }

  handleModalSubmit(url, text) {
    const quill = this.quillRef.current.getEditor();
    const { range } = this.state;
    if (range && url && text) {
      quill.deleteText(range.index, range.length);
      quill.insertText(range.index, text, 'link', url);
      quill.setSelection(range.index + text.length);
    }
    this.setState({ showModal: false, range: null, url: '', text: '', isEditing: false });
  }

  handleLinkClick(event) {
    if (event.target.tagName === 'A') {
      event.preventDefault();
      const quill = this.quillRef.current.getEditor();
      const range = quill.getSelection();
      const url = event.target.getAttribute('href');
      const text = event.target.innerText;

      // Prevent Quill's tooltip from showing
      quill.blur();
      // quill.focus();

      this.setState({
        showModal: true,
        range: { index: range.index, length: text.length },
        url,
        text,
        isEditing: true
      });
    }
  }

  handleModalRemove() {
    const quill = this.quillRef.current.getEditor();
    const textLength = quill.getLength();
    // let range = { index: 0, length: textLength };
    
    // Get the entire content and iterate over each character
    for (let i = 0; i < textLength; i++) {
        const [link] = quill.getFormat(i, 1).link || [];
        if (link) {
            // Remove the link format only, leaving the text intact
            quill.formatText(i, 1, 'link', false);
        }
    }

    this.setState({ showModal: false, url: '', text: '', isEditing: false });
  }

  handleChange(value) {
    this.setState({ note: value }, () => {
      this.customValidate();
    });
  }

  /* Handle note with MRO notes */
  changeHandlerForNote = (value) => {
    let category = this.state.category;
    category[this.state.index].task_name[this.state.indexes].note = value;
    this.setState({ category: category, note: value });
  };
  /* Image preview */
  imagePreview = async (event) => {
    this.setState({ isLoading: true });
    const files = event.target.files;
    const { category, index, indexes, isImage, arrAllImages } = this.state;
    let newImage = [];
    let allImages = [];

    if (files.length >= 1) {
      for (let i = 0; i < files.length; i++) {
        const fileSize = files[i].size;
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        if (fileSize >= 2e6) {
          // Compress the image if it's larger than 2MB
          const compressedFile = await imageCompression(files[i], options);
          allImages.push({
            file: compressedFile,
            isMrowithNote: category[index].task_name[indexes].is_mro_with_note,
            checklistTaskId:
              category[index].task_name[indexes].checklist_task_id,
            src: URL.createObjectURL(compressedFile),
          });
        } else {
          category[index].task_name[indexes].images = files[i];
          allImages.push({
            file: files[i],
            isMrowithNote: category[index].task_name[indexes].is_mro_with_note,
            checklistTaskId:
              category[index].task_name[indexes].checklist_task_id,
            src: URL.createObjectURL(files[i]),
          });
        }
      }

      // Merge new images with existing ones based on isImage flag
      newImage = isImage ? [...arrAllImages, ...allImages] : allImages;

      /* Assign single task image into the category */
      category[index].task_name[indexes].images = newImage;
      console.log('category',category);
      // Update state with new images
      this.setState({
        category: category,
        isImage: true,
        arrAllImages: newImage,
        isLoading: false,
      });
      this.localDataStorage(this.state.category);
    } else {
      console.log("First select an image file");
      this.setState({ isLoading: false });
    }
  };

  /* Image close */
  deleteImage(i) {
    let arrAllImages = [...this.state.arrAllImages];
    arrAllImages.splice(i, 1);
    let category = this.state.category;
    category[this.state.index].task_name[this.state.indexes].images =
      arrAllImages;
    this.setState({ arrAllImages, category: category });
  }
  /* handle create mro checkbox */
  handleCheckboxChange(e) {
    let category = this.state.category;
    const checked = e.target.checked;
    /* check box checked for mro */
    if (checked === true) {
      category[this.state.index].task_name[this.state.indexes].checked = true;

      /* If action item created then call related APIs like mrocategory or technician */
      this.getMroCategoryList();
      this.getUserList();
    } else {
      category[this.state.index].task_name[this.state.indexes].checked = false;
      category[this.state.index].task_name[this.state.indexes].location_name =
        null;
      category[this.state.index].task_name[this.state.indexes].location_slug =
        null;
      category[this.state.index].task_name[this.state.indexes].mro_category =
        null;
      category[this.state.index].task_name[
        this.state.indexes
      ].mro_category_slug = null;
      category[this.state.index].task_name[this.state.indexes].mro_priority =
        null;
      category[this.state.index].task_name[this.state.indexes].mro_priority_id =
        null;
      category[this.state.index].task_name[this.state.indexes].mro_technician =
        null;
      category[this.state.index].task_name[
        this.state.indexes
      ].mro_technician_slug = null;
      category[this.state.index].task_name[this.state.indexes].due_date = null;
    }
    this.setState({ category: category, checked: checked });
  }
  // Get MRO Category List
  async getMroCategoryList(queryString = "") {
    var mroCategoryList = [];
    var mroCategoryListData = [];
    let res = await MroCategoryService.getMroCategoryList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      mroCategoryList = res.data ? res.data : [];
    }
    if (mroCategoryList.length > 0) {
      mroCategoryList.forEach((item) => {
        mroCategoryListData.push({
          label: item.name,
          value: item.slug,
        });
      });
    }

    this.setState({ mroCategory: mroCategoryListData });
  }
  // Get Users List
  async getUserList(queryString = "") {
    this.setState({ loading: true });
    var userList = [];
    var userListData = [];
    queryString = "property_slug=" + (this.state.property_slug ? this.state.property_slug : '');
    let res = await UserService.getUserList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      userList = res.data ? res.data : [];
    }

    userList.forEach((item) => {
      userListData.push({
        label: item.full_name,
        value: item.slug,
      });
    });

    this.setState({ userList: userListData });
    this.setState({ loading: false });
  }
  // MRO Category Change Handler
  handleChangeMroCategory(value) {
    let mro_category_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        mro_category_slug.push(item.value);
      });
    }
    this.setState({
      selectedMroCategory: value,
      mro_category_slug: value.value,
    });

    /* Set category data into category array */
    let category = this.state.category;
    category[this.state.index].task_name[this.state.indexes].mro_category =
      value.label;
    category[this.state.index].task_name[this.state.indexes].mro_category_slug =
      value.value;
    this.setState({ category: category });
    this.setState({ confirm_back: 1 });
  }
  // Priority  Change Handler
  handleChangePriority(value) {
    let priority_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        priority_type.push(item.value);
      });
    }
    this.setState({
      selectedPriorityType: value,
      priority_type: value.value,
      mro_priority_id: value.value,
    });
    this.setState({ confirm_back: 1 });
    /* Set category data into category array */
    let category = this.state.category;
    category[this.state.index].task_name[this.state.indexes].mro_priority =
      value.label;
    category[this.state.index].task_name[this.state.indexes].mro_priority_id =
      value.value;
    this.setState({ category: category });
  }

  // Technician/User Change Handler
  handleChangeUserList(value) {
    let user_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_slug.push(item.value);
      });
    }
    this.setState({
      selectedUserList: value,
      user_slug: value.value,
      mro_technician_slug: value.value,
    });
    this.setState({ confirm_back: 1 });
    /* Set category data into category array */
    let category = this.state.category;
    category[this.state.index].task_name[this.state.indexes].mro_technician =
      value.label;
    category[this.state.index].task_name[
      this.state.indexes
    ].mro_technician_slug = value.value;
    category[this.state.index].task_name[this.state.indexes].zone_type =
      this.state.zone_type;
    category[this.state.index].task_name[this.state.indexes].location_slug =
      this.state.location_slug;
    category[this.state.index].task_name[this.state.indexes].location_name =
      this.state.location_name;
    this.setState({ category: category });
  }
  //Change handler for error and due date
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    if (event.target.name == "out_time") {
      this.setState({
        [event.target.name]: Moment(value).toDate("dd-MM-YYYY | hh:mm A"),
        input: input,
      });
    } else {
      this.setState({ [event.target.name]: value, input: input });
    }
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  /* Handle due date for mro */
  handleDueDate = (e) => {
    let category = this.state.category;
    category[this.state.index].task_name[this.state.indexes].due_date =
      e.target.value;
    this.setState({ category: category, due_date: e.target.value });
  };
  secondComponent = () => {
    const modules = {
      toolbar: {
        container: '#toolbar',
        handlers: {
          'link': this.handleLink
        }
      }
    };
    return (
      <div>
        <div className="row row-xs">
          {/* {localStorage.getItem("category").length > 0  ? JSON.parse(localStorage.getItem("category")):""} */}

          <div className="col-md-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1">
          {this.state.description ?
            <div>
              <label className="form-label text-left mb-0">
                Template Description
              </label>
              <p className="text-left mb-2">
                {this.state.description ? this.state.description : ""}
              </p>
            </div>
          : ""}
            <h4 className="form-label text-left mg-b-0">
              {" "}
              {this.state.checklist_name_heading}
              </h4>
            <span className="category_name" key={"secondComponent"}>
            <strong>{this.state.zone_name_heading}</strong>
            </span>
            {/* Category related loop */}
            {this.state.category != undefined
              ? this.state.category.map((element, index) => (
                  <div className="category_list_box" key={"cat_" + index}>
                    <div className="bedroom_title">{element.category_name}</div>
                    {element.task_name
                      ? element.task_name.map((el, indexes) => (
                          <>
                            <div className="qes_ans_box" key={indexes}>
                              <label className="check_cust">
                                <input
                                  key={"check" + indexes}
                                  type="checkbox"
                                  checked={(el.task_status === 1 || el.task_status === 2 || el.is_mro_with_note === 1 || el.is_mro_with_note === 2) ? true : false}
                                  name="task_status"
                                  onClick={(e) => {
                                    this.handleCheckboxClick(e, index, indexes);
                                  }}
                                />
                                <span className="checkmark"></span>
                                <span dangerouslySetInnerHTML={{ __html: this.modifyTaskName(el.task_name) }} />
                              </label>

                              <div
                                className={
                                  "checbox_cont_div d-block"
                                }
                              >
                                {/* d-block */}
                                <div className="d-flex" key={"ans" + indexes}>
                                  {/* Yes */}
                                  <button
                                    className={
                                      "gf-btn-lg-outline mr-2 " +
                                      (el.task_status === 1
                                        ? "inspect_button_yes"
                                        : "inspect_button_new")
                                    }
                                    onClick={(ev) => {
                                      this.handleAnswerOptionClick(
                                        ev,
                                        index,
                                        indexes
                                      );
                                    }}
                                    value="YES"
                                  >
                                    Yes
                                  </button>

                                  {/* No*/}
                                  <button
                                    className={
                                      "gf-btn-lg-outline mr-2 " +
                                      (el.task_status === 2
                                        ? "inspect_button_No"
                                        : "inspect_button_new")
                                    }
                                    onClick={(ev) => {
                                      this.handleAnswerOptionClick(
                                        ev,
                                        index,
                                        indexes
                                      );
                                    }}
                                    value="NO"
                                  >
                                    No
                                  </button>

                                  {/* Note*/}
                                  <button
                                    className={
                                      "gf-btn-lg-outline mr-2 " +
                                      (el.is_mro_with_note === 1 ||
                                      el.is_mro_with_note === 2
                                        ? "inspect_button_Note"
                                        : "inspect_button_new")
                                    }
                                    onClick={(ev) => {
                                      this.handleAnswerOptionClick(
                                        ev,
                                        index,
                                        indexes
                                      );
                                    }}
                                    value="NOTE"
                                  >
                                    {el.is_mro_with_note === 1
                                      ? "MRO"
                                      : el.is_mro_with_note === 2
                                      ? "NOTE"
                                      : "ADD NOTE"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      : ""}
                  </div>
                ))
              : ""}
          </div>
          <Modal show={this.state.showBasicModal} centered>
            <Modal.Body className="width_402 p-4 border_radius_14">
              <span className="welcomeClose" onClick={() => this.closeModal()}>
                {global.closee}
              </span>

              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-center mb-3">Notes</h3>
                  <p className="note_task_name_box font-weight-bold model_first_p">
                    <label className="form-label">Property Name :</label>{" "}
                    {this.state.property_inspection_perform}
                  </p>
                  <p className="note_task_name_box font-weight-bold model_first_p">
                    <label className="form-label">Task Name :</label>{" "}
                    <div dangerouslySetInnerHTML={{ __html: this.modifyTaskName(this.state.task_name) }} />
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <label className="form-label mg-b-5">Notes</label>
                  {/* <Form.Control
                    autoComplete="off"
                    name="note"
                    type="textarea"
                    as="textarea"
                    maxLength="500"
                    placeholder="Type here..."
                    onChange={this.changeHandlerForNote}
                    value={this.state.note}
                  /> */}
                   <CustomToolbar />
                    <ReactQuill
                      ref={this.quillRef}
                      theme="snow"
                      name="note"
                      value={this.state.note ?? ""}
                      onChange={this.changeHandlerForNote}
                      modules={modules}
                      formats={global.formats}
                      className="custom_editor_style"
                    />
                    {this.state.showModal && (
                      <LinkModal
                        onClose={this.handleModalClose}
                        onSubmit={this.handleModalSubmit}
                        onRemove={this.handleModalRemove}
                        url={this.state.url}
                        text={this.state.text}
                        isEditing={this.state.isEditing}
                        showModal={this.state.showModal}
                      />
                    )}
                  <div className="text-danger">
                    {this.state.noteMessage != undefined
                      ? this.state.noteMessage
                      : ""}
                  </div>
                </div>
              </div>
              {/* <input note /> */}

              <div className="note_image_box">
                <div className="">
                  <input
                    onChange={this.imagePreview}
                    type="file"
                    name="file_name"
                    tabIndex="2"
                    multiple
                    className="hidden"
                    title=""
                  />

                  {/* Image Preview */}
                  <ul className="img_view_loop">
                    {this.state.arrAllImages != undefined &&
                    this.state.arrAllImages.length > 0
                      ? this.state.arrAllImages.map((record, i) => {
                          return (
                            <li key={i}>
                              <div className="img_close">
                                <a
                                  href={record && record.src ? record.src : ""}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <img
                                    src={record && record.src ? record.src : ""}
                                    className="brand_list_image"
                                    alt={"note image"}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = placeholderImage;
                                    }}
                                  />
                                </a>
                                <button
                                  type="button"
                                  className="btn btn-danger btn_remove"
                                  onClick={() => this.deleteImage(i)}
                                >
                                  <span className="fa fa-times"></span>
                                </button>
                              </div>
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                </div>
                {/* Image Preview end*/}
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="qes_ans_box crete_mro_checkbox">
                    <label className="check_cust">
                      <input
                        type="checkbox"
                        defaultChecked={this.state.checked}
                        name="is_exclude"
                        onChange={(e) => this.handleCheckboxChange(e)}
                        value={this.state.is_mro_with_note}
                      />
                      <span className="checkmark"></span>Create MRO
                    </label>
                  </div>
                </div>
              </div>

              <div
                className={
                  "create_mro_field_section" +
                  (this.state.checked === true ? "d-block" : "")
                }
              >
                <div className="row">
                  <div className="col-md-12 mg-b-15">
                    <label className="form-label mg-b-5">MRO Category</label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedMroCategory}
                      options={this.state.mroCategory}
                      onChange={(value) => this.handleChangeMroCategory(value)}
                      defaultValue={this.state.selectedMroCategory}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />
                    {this.state.mro_category_slug == null ? (
                      <>
                        <div className="text-danger">
                          {this.state.mro_category_slug_error}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 mg-b-15">
                    <label className="form-label mg-b-5">Priority</label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedPriorityType}
                      options={this.state.priorityType}
                      isSearchable={true}
                      onChange={(value) => this.handleChangePriority(value)}
                      defaultValue={this.state.selectedPriorityType}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />

                    {this.state.mro_priority_id == null ? (
                      <>
                        <div className="text-danger">
                          {this.state.priority_type_error}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 mg-b-15">
                    <label className="form-label mg-b-5">Technician</label>

                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedUserList}
                      options={this.state.userList}
                      onChange={(value) => this.handleChangeUserList(value)}
                      defaultValue={this.state.selectedUserList}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />

                    {this.state.mro_technician_slug == null ? (
                      <>
                        <div className="text-danger">
                          {this.state.user_slug_error}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 mg-b-15">
                    <label className="form-label mg-b-5">Location</label>
                    <Form.Control
                      autoComplete="off"
                      name="location"
                      type="text"
                      maxLength="50"
                      value={this.state.location_name}
                      readOnly
                    />
                  </div>

                  <div className="col-md-12 mg-b-15">
                    <label className="form-label mg-b-5">Due Date</label>

                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      // scrollableYearDropdown
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="due_date"
                      placeholderText="mm-dd-yyyy"
                      dateFormat="dd MMM yyyy"
                      selected={this.state.due_date}
                      onChange={(data) =>
                        this.handleDueDate({
                          target: {
                            type: "date",
                            name: "due_date",
                            value: data,
                          },
                        })
                      }
                      minDate={Moment().toDate()}
                    />
                    {this.state.due_date == null ? (
                      <>
                        <div className="text-danger">
                          {this.state.due_date_error}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex cnt_center row mt-3">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeModal()}
                  disabled={this.state.isLoading}
                >
                  Close
                </button>

                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block btn-thene-modal"
                  onClick={this.submitModalforNote}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? global.loader : "Submit"}
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  };

  thirdComponent = () => {
    return (
      <div>
        <div className="row row-xs">
          <div className="col-md-12 d-none">
            <div className="az-content-label titleLable pl-0">Review</div>
          </div>

          <div className="col-md-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1">
            {this.state.isMro === true ? (
              <>
                <label className="text-left mg-b-5">
                  Below MROs will be automatically created.
                </label>
                <p>
                  After submitting inspection, below MROs will be automatically
                  created. You can update MRO details if needed.
                </p>
              </>
            ) : (
              <>
                <label className="text-left mg-b-5">
                  No MROs will be automatically created.
                </label>
              </>
            )}
          </div>

          <div className="col-md-12">
            {this.state.category != undefined
              ? this.state.category.map((element, index) => (
                  <span key={"rev" + index}>
                    {element.task_name
                      ? element.task_name.map((el, indexes) => (
                          <>
                            <span key={"revs" + indexes}>
                              {el.is_mro_with_note === 1 ? (
                                <>
                                  <p className="titleLable pl-4 insp_cat_list_box mb-1">
                                    <span dangerouslySetInnerHTML={{ __html: this.modifyTaskName(el.task_name) }} />
                                  </p>
                                </>
                              ) : (
                                ""
                              )}
                            </span>
                          </>
                        ))
                      : ""}
                  </span>
                ))
              : ""}
          </div>
        </div>

        <div className="row row-xs">
          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
            <label className="form-label mg-b-5">Out-Time</label>
            <DatePicker
              className="form-control max_width_100"
              showMonthDropdown
              showYearDropdown
              // scrollableYearDropdown
              autoComplete="off"
              yearDropdownItemNumber={global.yearDropdownItemNumber}
              name="out_time"
              placeholderText="mm-dd-yyyy"
              dateFormat="dd MMM yyyy | hh:mm a"
              selected={this.state.out_time}
              onChange={(data) =>
                this.changeHandler({
                  target: {
                    type: "date",
                    name: "out_time",
                    value: data,
                  },
                })
              }
            />
            {this.state.errors.out_time ? (
              <div className="text-danger">{this.state.errors.out_time}</div>
            ) : (
              this.validator.message(
                "Out Time",
                this.state.out_time,
                "required",
                { className: "text-danger" }
              )
            )}
          </div>
        </div>
      </div>
    );
  };
  modifyTaskName(taskName) {
    // Create a temporary element to manipulate the HTML string
    const tempElement = document.createElement('div');
    tempElement.innerHTML = taskName;

    // Find all links in the element
    const links = tempElement.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
    });

    // Return the modified HTML
    return tempElement.innerHTML;
  }
  
  handleNext = async (e) => {
    const { steps, activeStep } = this.state;
    if (steps[steps.length - 1].key === activeStep.key) {
      this.saveOrUpdateInspection();
      return;
    }
    const index = steps.findIndex((x) => x.key === activeStep.key);
    /* First Continioue button  */
    if (this.state.activeStep.key === "firstStep") {
      if (this.state.backIndex === 1) {
        if (
          this.state.property_slug != undefined &&
          this.state.zone_type != undefined &&
          this.state.checklist_slug != undefined &&
          this.state.location_slug != undefined
        ) {
          this.setState({ steps, activeStep: steps[index + 1] });
        } else {
          this.validator.showMessages();
        }
      } else {
        if (this.state.location_slug != undefined) {
          this.setState({ location_slug_error: "" });
          var match = await this.checkInspectionAvailableOnChecklistInLocal();
          /* If key avaialble then provide the resume or new start option throught open model */
          if (match) {
            this.setState({ showConfirmModel: true });
          } else {
            this.checklistDetail(this.state.checklist_slug);
            this.setState({ steps, activeStep: steps[index + 1] });
          }
        } else {
          this.setState({
            location_slug_error: "This location field is required",
          });
        }
      }
    }
    /* Secound Continioue button  */
    if (this.state.activeStep.key === "secondStep") {
      /* Is any mro is available in the category */
      var isMro = await this.isAnyCheckedTrue(this.state.category);

      var outTime = Moment(new Date()).toDate("dd-MM-YYYY | hh:mm A");
      this.setState({
        steps,
        activeStep: steps[index + 1],
        sect: true,
        out_time: outTime,
        isMro: isMro,
      });
    }

    this.customValidate();
  };
  isAnyCheckedTrue(data) {
    // Iterate over each category
    for (let j = 0; j < data.length; j++) {
      const tasks = data[j].task_name;
      // Iterate over each task
      for (let k = 0; k < tasks.length; k++) {
        // Check if the task is checked
        if (tasks[k].checked === true) {
          // If any task is checked, return true
          return true;
        }
      }
    }

    // If no task is checked, return false
    return false;
  }
  handleBack = () => {
    const { steps, activeStep } = this.state;
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;
    steps[index].isDone = false;
    this.setState({ backIndex: index });
    this.setState({ steps, activeStep: steps[index - 1] });
  };
  checkInspectionAvailableOnChecklistInLocal = async () => {
    const localInsp = localStorage.getItem("localInsp") || "";
    var newKey = `${global.userData.slug}/${this.state.property_slug}/${this.state.checklist_slug}`;
    this.setState({
      localInsp: localInsp ? JSON.parse(localInsp) : "",
      newKey: newKey,
    });

    /* Find the key available in the existing array or not   */
    var match = false;
    if (localInsp.includes(newKey)) {
      match = true;
    }

    return match;
  };
  startNew = async () => {
    const { localInsp, newKey, steps, activeStep, checklist_slug } = this.state;
    const index = localInsp
      ? localInsp.findIndex((x) => x.uniqueKey === newKey)
      : -1;
    const index1 = steps.findIndex((x) => x.key === activeStep.key);

    if (index !== -1) {
      localInsp.splice(index, 1);
      await localStorage.setItem("localInsp", JSON.stringify(localInsp));
      await this.checklistDetail(checklist_slug);
    }
    this.setState({ showConfirmModel: false });
    this.setState({
      steps,
      activeStep: steps[index1 + 1],
      checklist_slug: this.state.checklist_slug,
    });
  };
  resumeHandle = () => {
    const { steps, activeStep } = this.state;
    /* conver into the object */
    // const localInsp = localStorage.getItem("localInsp") || "";
    // var localInspObj = JSON.parse(localInsp);
    /* Check the uniq key in the array */
    // const newKey = `${global.userData.slug}/${this.state.property_slug}/${this.state.checklist_slug}`;

    // const indexLocal = localInspObj
    //   ? localInspObj.findIndex((x) => x.uniqueKey === newKey)
    //   : -1;
    // if (indexLocal !== -1) {
    //   var pendingInsp = localInspObj[indexLocal];
    // }
    // this.setState({
    //   showConfirmModel: false,
    //   category: pendingInsp ? pendingInsp.category : "",
    //   checklist_name_heading: pendingInsp
    //     ? pendingInsp.checklist_name_heading
    //     : "",
    //   checklist_slug: pendingInsp ? pendingInsp.checklist_slug : "",
    //   property_inspection_perform: pendingInsp
    //     ? pendingInsp.property_inspection_perform
    //     : "",
    //   property_slug: pendingInsp ? pendingInsp.property_slug : "",
    //   zone_name_heading: pendingInsp ? pendingInsp.zone_name_heading : "",
    //   uniqueKey: pendingInsp ? pendingInsp.uniqueKey : "",
    // });
    // this.localDataStorage(this.state.category);
    this.setState({ showConfirmModel: false });
    const indexTab = steps.findIndex((x) => x.key === activeStep.key);
    this.setState({ steps, activeStep: steps[indexTab + 1] });
  };

  onImageUpload = async (image) => {
    const fileData = new FormData();
    fileData.append("file_name", image.file, image.file.name);
    fileData.append("is_mro_with_note", image.isMrowithNote);
    fileData.append("task_slug", image.task_slug);
    fileData.append("is_completed", true);
    fileData.append("refTableId", 2);
    let res = await InspectionService.uploadInspFile(fileData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        Images: res.data.fileName,
        imageUrl: res.data.filePath,
      });
      this.setState({ loading: false });
    } else if (res.status === 413) {
      this.setState({
        loading: false,
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: "Content Too Large",
      });
    } else {
      let alertMessage = "";
      if (res.data.result.errorDetail !== null) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
      this.setState({
        showSpinner: false,
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  saveOrUpdateInspection = async (e) => {
    this.setState({ loading: true, isSubmit: true });
    let inputData = {
      property_slug: this.state.property_slug,
      zone_type: this.state.zone_type,
      checklist_slug: this.state.checklist_slug,
      location_slug: this.state.location_slug,
      user_slug: global.userData.slug,
      in_time: localStorage.getItem("InTime"),
      category: this.state.category,
      out_time: this.state.out_time,
      is_image: this.state.is_image,
      // imageData: inputFilesData,
    };
    if (inputData != undefined) {
      let res = await InspectionService.createInspection(inputData);
      if (global.successStatus.includes(res.status)) {
        let responceData = res.data && res.data.data ? res.data.data : [];
        this.setState({
          inspectionData: responceData,
        });

        /* Update localstorage delete the Insp in the localstorage */

        const { localInsp, newKey } = this.state;
        /* Find the index */
        const index = localInsp
          ? localInsp.findIndex((x) => x.uniqueKey === newKey)
          : -1;
        if (index !== -1) {
          localInsp.splice(index, 1);
          await localStorage.setItem("localInsp", JSON.stringify(localInsp));
        }

        this.setState({ loading: false, isSubmit: false });
        setTimeout(
          () => this.props.history.push("/inspections"),
          global.redirect_time
        );
        this.getImageForUpload();
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
  };
  async getImageForUpload() {
    if (this.state.isImage === true) {
      if (this.state.arrAllImages.length > 0) {
        this.state.arrAllImages.forEach((image, index) => {
          var categoryResultListData = this.state.inspectionData.category;
          for (var i in categoryResultListData) {
            var taskList = categoryResultListData[i].task_name;
            for (var k in taskList) {
              if (taskList[k].checklist_task_id == image.checklistTaskId) {
                image.task_slug = taskList[k].slug;
                this.onImageUpload(image);
              }
            }
          }
        });
      }
    }
  }
  localDataStorage = async (category) => {
    if (category !== undefined && category !== null) {
      const localInsp = localStorage.getItem("localInsp") || "";
      if (localInsp != "" && localInsp != undefined) {
        let finalLocalArr = [];

        /* conver into the object */
        var localInspObj = JSON.parse(localInsp);

        /* Check the uniq key in the array */
        const newKey = `${global.userData.slug}/${this.state.property_slug}/${this.state.checklist_slug}`;

        // find the index of unique key
        const index = localInspObj
          ? localInspObj.findIndex((x) => x.uniqueKey === newKey)
          : -1;

        // Key available in the array,  index > -1  in the existing array
        if (index !== -1) {
          const newArr = [
            {
              property_slug: this.state.property_slug,
              checklist_slug: this.state.checklist_slug,
              property_inspection_perform:
                this.state.property_inspection_perform,
              checklist_name_heading: this.state.checklist_name_heading,
              zone_name_heading: this.state.zone_name_heading,
              category: category,
              uniqueKey: newKey,
            },
          ];

          /*  remove the index from the existing array*/
          const arrAfterRemoveKey = localInspObj.filter(
            (item) => item.uniqueKey !== newKey
          );

          /* Update the new object in the existing array */
          let finalLocalArr = [...arrAfterRemoveKey, ...newArr];

          /* store new array in the localstorage */
          await localStorage.setItem(
            "localInsp",
            JSON.stringify(finalLocalArr)
          );
        }

        /* Key not avaialble in the array, Index == -1 are  find in the existing array */
        if (index === -1) {
          const newArr = [
            {
              property_slug: this.state.property_slug,
              checklist_slug: this.state.checklist_slug,
              property_inspection_perform:
                this.state.property_inspection_perform,
              checklist_name_heading: this.state.checklist_name_heading,
              zone_name_heading: this.state.zone_name_heading,
              category: category,
              uniqueKey: newKey,
            },
          ];

          /* if localInspObj get null then remove the key */
          if (localInspObj == null) {
            localStorage.removeItem("localInsp");
            /* Add new object in the existing array  =========== No need to remove the Index */
            finalLocalArr = newArr;
          } else {
            /* Add new object in the existing array  =========== No need to remove the Index */
            finalLocalArr = [...localInspObj, ...newArr];
          }

          /* store new array in the localstorage */
          await localStorage.setItem(
            "localInsp",
            JSON.stringify(finalLocalArr)
          );
        }
      } else {
        /* Create first object  */
        const newKey = `${global.userData.slug}/${this.state.property_slug}/${this.state.checklist_slug}`;
        const newData = [
          {
            property_slug: this.state.property_slug,
            checklist_slug: this.state.checklist_slug,
            property_inspection_perform: this.state.property_inspection_perform,
            checklist_name_heading: this.state.checklist_name_heading,
            zone_name_heading: this.state.zone_name_heading,
            category: category,
            uniqueKey: newKey,
          },
        ];

        // Set the final array to the local storage key
        await localStorage.setItem("localInsp", JSON.stringify(newData));
      }
    }
  };
  render() {
    const { steps, activeStep } = this.state;
    return (
      <>
        <main>
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle heading_alignment">
                  <h3 className="flex-column">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h3>Inspection</h3>
                      <Link
                        to={`/start-inspection-list/${this.state.checklist_slug}`}
                      >
                        <img src={back} alt="" />
                        Back
                      </Link>
                    </div>
                    <span>{this.state.property_inspection_perform ?? ""}</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-12 pl-0 pr-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>
          </div>
          <div className="container-fluid">
            <div className="inspection_perform_section">
              <div className="row row-xs mg-t-20 justify-content-center">
                <div className="col-md-12 col-xl-12">
                  <div className="box dashboardRightcard p-0">
                    <div className="steps">
                      <ul className="nav">
                        {steps.map((step, i) => {
                          return (
                            <li
                              key={i}
                              className={`${
                                activeStep.key === step.key ? "active" : ""
                              } ${step.isDone ? "done" : ""}`}
                            >
                              <div>
                                <span>{step.label}</span>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="step-component pd-l-70 pd-r-70">
                      <form
                        id="insp_form"
                        method="post"
                        encType="multipart/form-data"
                      >
                        {activeStep.component()}
                        <div>
                          <div className="btn-component mg-t-5 mg-md-t-0">
                            <input
                              type="button"
                              value="Back"
                              onClick={this.handleBack}
                              disabled={steps[0].key === activeStep.key}
                            />
                            {/* <input type="button"  className='mr-0' value={steps[steps.length - 1].key !== activeStep.key ? 'Continue' : 'Submit'} onClick={this.handleNext} /> */}
                            <button
                              className="gf-btn-lg-stroke mg-l-2"
                              type="button"
                              onClick={this.handleNext}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : activeStep.key == "finalStep"
                                ? "Submit"
                                : "Continue"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal show={this.state.showConfirmModel} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Resume Inspection</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Would you like to resume inspection or would like to start new?
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.resumeHandle()}
                >
                  Resume
                </button>
                <button
                  type="button"
                  className="m-0 btn btn-success btn-block"
                  onClick={() => this.startNew()}
                >
                  Start New
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default App;
