////////////////////////////////////////////////////////////
//  Program: TrackerService.js                            //
//  Application: ZoneTypeService                          //
//  Option: Used for country list                         //
//  Developer: Kiran singh 						          //
//  Date: 2022-03-31                                      //
////////////////////////////////////////////////////////////

import ApiService from './ApiService'

class TrackeService {

    getRoomTrackerData(queryString) {
        let endPoint = 'room-tracker' + (queryString ? '?' + queryString : '');
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }
    getCommerTrackerData(queryString) {
        let endPoint = 'commercial-tracker' + (queryString ? '?' + queryString : '');
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    } 
    
    getEquipZoneTrackerData(queryString) {
        let endPoint = 'equipment-zone-tracker' + (queryString ? '?' + queryString : '');
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    getEquipTrackerData(queryString) {
        let endPoint = 'equipment-tracker' + (queryString ? '?' + queryString : '');
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    } 

}

export default new TrackeService()
