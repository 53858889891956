////////////////////////////////////////////////////////////
//     							                          //
//  Program: TrackersView.jsx                             //
//  Application:  Properties                              //
//  Option: For add or update Properties                  //
//  Developer: kiran singh                                //
//  Date: 2022-05-03                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import { Tab, Nav } from "react-bootstrap";
import PropertyService from "../../services/PropertyService";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import Alerts from "../common/Alerts";
import RoomTracker from "../tracker/RoomTracker";
import CommercialsTracker from "../tracker/CommercialsTracker";
import EquipmentTracker from "../tracker/EquipmentTracker";
import MroTracker from "../mro/MroTracker";
import EquipmentZonesTracker from "./EquipmentZonesTracker";

export class TrackerView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleSwitchDark2: true,
      addModal: false,
      editModal: false,
      deleteModal: false,
      property_name: "",
      status_id: 1,
      open: false,
      eventKey: "",
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      tab: 1,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
  }

  async componentDidMount() {
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data.data ? res.data.data : {};
        localStorage.setItem(
          "property_name",
          res.data.property_name ? res.data.property_name : ""
        );
      }
      this.setState(resData);
    }
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "room") {
      this.setState({ tab: 1 });
    } else if (e === "commercials") {
      this.setState({ tab: 2 });
    } else if (e === "equipment") {
      this.setState({ tab: 3 });
    } else if (e === "equipment-zones") {
      this.setState({ tab: 4 });
    } else if (e === "mro") {
      this.setState({ tab: 5 });
    } else {
      this.setState({ tab: 1 });
    }
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.eventKey });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  openAddModal() {
    this.setState({ addModal: true });
  }

  closeAddModal() {
    this.setState({ addModal: false });
  }

  openEditModal() {
    this.setState({ editModal: true });
  }

  closeEditModal() {
    this.setState({ editModal: false });
  }

  openDeleteModal() {
    this.setState({ deleteModal: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModal: false });
  }

  render() {
    return (
      <main>
        {this.state.showSpinner ? global.spinnerLoader : ""}
        <div className="container-fluid">
          <div className="row row-xs">
            {/* <div className="col-md-10 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
                    <div className="backpage">
                         <Link to="/properties">Properties</Link>
                          <span> / </span>
                          <span></span>
                    </div>
                </div> */}
            <div className="col-md-12">
              <div className="dashbaord-pageHeadertitle heading_alignment">
                <h3 className="flex-column">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    Trackers
                    {/* <Link to="/properties"><img src={back}/>Back</Link> */}
                  </div>
                  {/* <span>
                    {" "}
                    {this.state.customer
                      ? this.state.customer.customer_name
                      : ""}
                  </span> */}
                  <span>all Trackers</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-12 pl-0 pr-0">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
        </div>

        <div className="container-fluid">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-12">
                <div className="scrolling-carousel pd-l-0">
                  <Tab.Container
                    id="left-tabs-example"
                    eventKey={this.state.activeClass}
                    onSelect={(e) => this.getActiveClass(e)}
                    defaultActiveKey="room"
                  >
                    <ScrollingCarousel rightArrow={true} leftArrow={true}>
                      <div className="swipesection ">
                        <Nav variant="pills" className="">
                          <Nav.Item>
                            <Nav.Link eventKey="room">Rooms</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="commercials">
                              Commercial Areas
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="equipment-zones">Equipment Zones</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="equipment">Equipment</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="mro">MROS</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </ScrollingCarousel>
                    <Tab.Content>
                      <Tab.Pane eventKey="room">
                        {this.state.tab === 1 && (
                          <RoomTracker
                            propertySlug={this.state.property_slug}
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="commercials">
                        {this.state.tab === 2 && (
                          <CommercialsTracker
                            propertySlug={this.state.property_slug}
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="equipment">
                        {this.state.tab === 3 && (
                          <EquipmentTracker
                            propertySlug={this.state.property_slug}
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="equipment-zones">
                        {this.state.tab === 4 && (
                          <EquipmentZonesTracker
                            propertySlug={this.state.property_slug}
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="mro">
                        {this.state.tab === 5 && (
                          <MroTracker propertySlug={this.state.property_slug} />
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
            <div className="ht-50"></div>
          </div>
        </div>
      </main>
    );
  }
}

export default TrackerView;
